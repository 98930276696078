import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import Feature from "./home_container/feature";
import Work from "./home_container/work";
import "../../styles/home/home.css";
import Choose from "./home_container/whychoose";
import ImageCard from "./home_container/imagecard";
import People from "./home_container/people";
import Joinus from "./home_container/joinus";
import Appcontent from "./home_container/appcontent";
import HomeFAQ from "./home_container/homefaq";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();

  const scrolldown = x => {
    window.scrollTo({ behavior: 'smooth', top: x })
  }

  return (
    <>
      <div className="mt-md-5 pt-5">
        <div className="">
          <div className="row chhose-book-and-get-container chhose-book-and-get-container-top-space">
            {/* <div className=""> */}
              <div className="col-xl-6 col-md-6 overflow-y-hidden">
                <div className=" justify-content-center">
                  <div className="chhose-book-and-get-image-container image-height-home-page home-conatiner-mobile-height">
                    <img
                      src="assets/homepage/home_chart.png"
                      alt=""
                      className="home-chart-img"
                    />
                    <img
                      src="assets/homepage/home_woman.png"
                      alt=""
                      className="position-absolute home-woman"
                    />
                    <img
                      src="assets/homepage/home_man.png"
                      alt=""
                      className="position-absolute home-man"
                    />
                    <div className="position-absolute shadow home-cng-subimg">
                      <div className="d-flex shadow justify-content-center align-items-center">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div className="d-flex justify-content-center">
                            <img
                              src="assets/homepage/home_cng..png"
                              className=""
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="position-absolute home-dot-img">
                    <img
                      src="assets/homepage/home_img_1.png"
                      alt=""
                      className="w-52 pl-xl-0 pl-md-0 pl-5 pb-28"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6  justify-content-center ">
              <div className="chhose-book-and-get-container-text">
              <span className="my-4 home-look-title">
                  Choose, Book & Get <br></br> Your Hair Styled <br></br> On Demand.
              </span>
                <p className="home-look-desc">
                  Now, you can do all this with Instacuts and experience salon services by a professional stylist from the comfort of your home!
              </p>
                <div className="row my-4 justify-content-md-start justify-content-center text-center padding-for-home-page-button">
                  <div className="col-5 padding-left-and-right-zero-for-home-page">
                  <button
                    type="button"
                    className="btn home-btns home-header-btn home-header-sub-btn mr-1  padding-7px-under-320 element-center-under-767px become-astylist-button-padding"
                    onClick={() => history.push("/stylist")}
                  >
                    BECOME A STYLIST
                  </button>

              
                  </div>
                  <div className="col-7 display-none-under-991px padding-left-and-right-zero-for-home-page">
                    
                  <button
                  onClick={()=>scrolldown(15000)}
                    type="button"
                    className="btn home-join-btns home-header-btn download-now-button-width ml-1  d-flex justify-content-center align-items-center text-center text-white padding-7px-under-320 home-book-your-Button"
                  >
                    DOWNLOAD NOW <BsArrowRightShort size="20px" />
                  </button>
                  </div>
                  <div className="col-7 display-none-after-992px padding-left-and-right-zero-for-home-page pl-0">
                    
                  <button
                  onClick={()=>scrolldown(15000)}
                    type="button"
                    className="btn home-join-btns home-header-btn download-now-button-width ml-1  d-flex justify-content-center align-items-center text-left text-white padding-7px-under-320 "
                  >
                    DOWNLOAD NOW <BsArrowRightShort size="20px" />
                  </button>
                  </div>
               </div>
              </div>
              </div>
            {/* </div> */}
          </div>
        </div>
        <Work />
        <Feature />
        <Choose />
        <ImageCard />
        {/* <People /> */}
        <Joinus />
        <Appcontent />
        <HomeFAQ />
      </div>
    </>
  );
};

export default Home;
