import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import StylistStandard from "./stylistSafety_component/stylistStandard";
import StylistDrives from "./stylistSafety_component/stylistDrives";
import StylistProtocol from "./stylistSafety_component/stylistProtocol";

const StylistSafety = () => {
  const scrolldown = () => {
    window.scrollTo({ behavior: 'smooth', top: 900 })
  }
  return (
    <div className="mt-5 pt-5">
      <div className="chhose-book-and-get-container-safety safety-top-sapce">
        <div className="content-imagecard justify-content-center">
          <div className="Row home-img-card  d-md-flex home-common align-items-center safety-containerr-inner-padding">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <span className="safety-heding-bottom-space safety-card-top-space-45px">
                SAFETY
              </span>
              <span className="home-safety-card-title">
              Style Safely at <br /> your own pace
              </span>
              <p className="home-imgcard-contents">
              We will make sure that you only engage with legitimate clients – all spam or fake bookings will be flagged by our app.
              </p>
              <div className="">
              <button
                  type="button"
                  onClick={()=>scrolldown()}
                  className="btn home-btns home-header-btn px-4 d-flex align-items-center home-join-btns text-white"
                >
                  Explore <BsArrowRightShort size="20px" />
                </button>
              </div>
            </div>
            <div className="col-md-6 home-img-main-content safety-card-bottom-space-45px px-0">
              <img
                className="home-img padding-top-for-20px-safety-image"
                src="assets/safetypage/stylistsaftypng.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <StylistStandard />
      <StylistProtocol />
      <StylistDrives />
    </div>
  );
};

export default StylistSafety;
