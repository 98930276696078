import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
// import { BsBellFill } from "react-icons/bs";

const Joinus = () => {
  return (
    <div className="chhose-book-and-get-container align-items-center text-center why-choice-top-space mt-5">
      <div className="overflow-y-hidden">
        <span className="home-work-title2 text-center">Why join us ?</span>
      </div>
      <div className="home-work-desc d-block  text-center justify-content-center feature if-your-padding">
        If you’re a Stylist looking to earn more money and the freedom to work whenever, wherever you like, then Instacuts is your answer!
      </div>
      

      <div className="">
        <div className="row">
          <div className="col-lg-5  d-flex flex-column padding-for-flexibility ">
            <div className="d-lg-flex flexibility-safety-top-space ">
              <div className="col-lg-3 d-flex justify-content-center">
                <div className="d-flex align-items-center text-white justify-content-center  home-join-icons">
                  1
                </div>
              </div>
              <div className="flex-column justify-content-lg-start justify-content-center text-lg-left text-center join-points">
                <span className="home-choose-headings2">Flexibility</span>
                <p className="home-choose-desc responsive w-100-425 flexibility-its-text-space-bw over-x-unset width-90-under-767-px join-content-margin">
                  Take on new clients as you see fit and stay in control of your
                  own schedule.
                </p>
              </div>
            </div>
            <div className="d-lg-flex flexibility-safety-top-space">
              <div className="col-lg-3 d-flex justify-content-center">
                <div className="d-flex align-items-center text-white justify-content-center  home-join-icons">
                  2
                </div>
              </div>
              <div className=" justify-content-lg-start justify-content-center  text-lg-left text-center join-points">
                <span className="home-choose-headings2">Safety</span>
                <p className="home-choose-desc  responsive flexibility-its-text-space-bw over-x-unset width-90-under-767-px join-content-margin">
                  You can choose whom to serve and where, with just a tap of a
                  button.
                </p>
              </div>
            </div>
            <div className="d-lg-flex flexibility-safety-top-space">
              <div className="col-lg-3 d-flex justify-content-center">
                <div className="d-flex align-items-center text-white justify-content-center  home-join-icons">
                  3
                </div>
              </div>

              <div className="row flex-column justify-content-lg-start justify-content-center  text-lg-left text-center join-points ml-1">
                <span className="home-choose-headings2">Income</span>
                <p className="home-choose-desc  responsive flexibility-its-text-space-bw over-x-unset width-90-under-767-px join-content-margin">
                Make more than working from a salon - our stylists earn 150% more than an average salon stylist.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 p-lg-1 d-flex justify-content-center align-items-center">
            <img
              src="assets/homepage/undraw_stand_out_1oag1.png"
              alt=""
              className=" postition-absolute why-join-us-image"
            />
            <div className="position-absolute justify-content-center stylist-cng-subimg-1 over-x-unset">
              <div className="d-flex shadow justify-content-center align-items-center p-md-2">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="col-lg-12 d-flex align-items-center">
                      <svg
                        width="37"
                        height="36"
                        viewBox="0 0 29 28"
                        fill="none"
                      >
                        <circle
                          cx="14.5932"
                          cy="13.9999"
                          r="13.7143"
                          fill="black"
                        />
                        <path
                          d="M19.8187 17.6618C19.8061 17.6445 18.5491 15.9051 18.5491 14.4624V11.8862C18.5491 9.75206 16.7779 8.02197 14.5931 8.02197C12.4082 8.02197 10.6371 9.75206 10.6371 11.8862V14.4624C10.6371 15.9051 9.3801 17.6445 9.36744 17.6618C9.31005 17.7403 9.3024 17.8436 9.34761 17.9294C9.39283 18.0152 9.48334 18.0691 9.58212 18.0691H19.604C19.7028 18.0691 19.7933 18.0152 19.8386 17.9294C19.8838 17.8436 19.8761 17.7403 19.8187 17.6618Z"
                          fill="white"
                        />
                        <path
                          d="M14.845 20.0786C15.5907 20.077 16.2271 19.4406 16.352 18.5715H13.3379C13.4628 19.4406 14.0992 20.077 14.845 20.0786Z"
                          fill="white"
                        />
                      </svg>

                      <div className="d-flex px-2 flex-column stylist-cng-subimg-details-1 text-left">
                        <span>New Order!</span>

                        <span>You’ve earned a total of $3,245..</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-lg-6 d-flex justify-content-lg-end mx-w-90 home-join-now mt-4">
          <img src="assets/homepage/home_img_1.png" alt="" />
          <div className="m-5 position-absolute right-10px-under-768">
            <a href="/stylist">
            <button
              type="button"
              className="btn home-join-btns home-header-btn px-5 d-flex align-items-center text-white"
            >
              LEARN MORE <BsArrowRightShort size="20px" className=""/>
            </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Joinus;
