import React from "react";

const Feature = () => {
  return (
    <div className="align-items-center chhose-book-and-get-container text-center what-we-offer-top-space">
      <div className="">
        <span className="home-work-heading">What We Offer</span>
      </div>
      <div className="overflow-y-hidden our-feature-top-space">
        <span className="home-work-title">Our Features</span>
      </div>
      <div className="home-work-desctext-center justify-content-center feature We-have-combined">
        We have combined on-demand Salon services with the convenience experienced through ride sharing and home delivered groceries.
      </div>

        <div className="row w-100  home-feature-section margin-0-for-center-image-smallscreen our-feature-section-margin-adjust">
          <div className="col-xl-6 col-md-6 d-md-none text-center padding-0px-under-767 ">
          <img
              src="assets/homepage/home_feature_img_a.png"
              className=" quality-service-image"
              alt=""
            />
          </div>
          <div className="col-xl-6 col-md-6 px-xl-3  py-xl-0  pt-5   text-left d-flex justify-content-center flex-column padding-0px-under-767">
            
            <div className="overflow-y-hidden qulaity-services-left-padding ">
              <span className="home-feature-tag text-xl-left text-md-left text-center">
                Quality services at your comfort!
            </span>
            </div>
            
            <p className="home-work-desc text-xl-left text-md-left   featere-desc  text-center text-md-left ">
              Enjoy professional salon services without the
              hassle and time-consuming nature of going to the local salon.
            </p>
            
          </div>

          <div className="col-xl-6 col-md-6  text-right dispaly-none-on-mobile-under-768">
            <img
              src="assets/homepage/home_feature_img_a.png"
              className="quality-service-image"
              alt=""
            />
          </div>
        </div>
      
      <div className="d-xl-flex your-safety-div-top-space">
        <div className="row w-100  home-feature-section margin-0-for-center-image-smallscreen our-feature-section-margin-adjust">
          <div className="col-xl-6 col-md-6 text-left  d-md-none text-center padding-0px-under-767">
            <img
              src="assets/homepage/home_feature_img_b.png"
              className="quality-service-image"
              alt=""
            />
          </div>
          <div className="col-xl-6 col-md-6 text-left  dispaly-none-on-mobile-under-768">
            <img
              src="assets/homepage/home_feature_img_b.png"
              className="quality-service-image"
              alt=""
            />
          </div>
          <div className="col-xl-6 col-md-6 px-xl-3 px-md-3 px-0 py-xl-0 pt-5 col-sm-12 text-left d-flex justify-content-center flex-column">
            
            <div className="overflow-y-hidden qulaity-services-left-padding your-safety-text-left-space">
              <span className="home-feature-tag text-xl-left text-md-left text-center">
                Your safety is our priority!
            </span>
            </div>
            
            <p className="home-work-desc  pr-xl-5 pr-md-0 text-xl-left text-md-left  featere-desc your-safety-text-left-space">
              Our rigorous safety protocols minimize the chance of being exposed to COVID-19. <a class="learnmore" href="/safety"><strong>Learn More</strong></a>
            </p>
            
          </div>
        </div>
      </div>
      <div className="justify-content-center your-safety-div-top-space">
        <div className="row w-100 justify-content-center home-feature-section margin-0-for-center-image-smallscreen our-feature-section-margin-adjust">
          <div className="col-xl-6 col-md-6 d-md-none d-flex px-md-4 align-items-center justify-content-center padding-0px-under-767">
          <img
              src="assets/homepage/home_feature_img_c.png"
              className="quality-service-image"
              alt=""
            />
          </div>
          <div className="col-xl-6 col-md-6 pt-5 text-left  justify-content-center ">
           
            <div className="overflow-y-hidden qulaity-services-left-padding padding-left-20px-on-768">
              <span className="home-feature-tag text-xl-left text-md-left text-center">
                Rewards for Referrals
            </span>
            </div>
            
            <p className="home-work-desc text-xl-left text-md-left   featere-desc padding-left-20px-on-768 text-center">
              Earn wallet credits and exciting rewards for every friend you refer or invite to our platform. What’s special? Both you and your friend will win rewards!
            </p>
            
          </div>

          <div className="col-xl-6 col-md-6  text-right dispaly-none-on-mobile-under-768">
            <img
              src="assets/homepage/home_feature_img_c.png"
              className="quality-service-image"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
