import React from "react";
// import { BsArrowRightShort } from "react-icons/bs";

const Standard = () => {
  return (
    <div>
      <div className="covid-19-door-top-div-space chhose-book-and-get-container">
        <div className="align-items-center">
          <div className="row justify-content-center text-center customer-heading">
            COVID-19 Door-to-Door Safety
          </div>
          <div className="col-lg-12 text-center my-3 home-work-desc covid-19-padding COVID-19-Door-to-Door-Safety-content-padding">
            Our Protocols are in compliance with the best practices recommended
            by the CDC & the WHO
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center">
          <div
              className="col-lg-4 py-3 my-lg-0 my-3 d-flex flex-column align-items-center overflow-y-hidden"
              align="center"
            >
              <div className="row over-x-unset">
                <div className="covid-19-door-bg">
                <svg
                  width="37"
                  height="40"
                  viewBox="0 0 37 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="covid-19-icon-top-space"
                >
                  <path
                    d="M33.2292 8H27.5V4.25C27.5 2.17969 25.9603 0.5 24.0625 0.5H12.6042C10.7064 0.5 9.16667 2.17969 9.16667 4.25V8H3.4375C1.53971 8 0 9.67969 0 11.75V34.25C0 36.3203 1.53971 38 3.4375 38H33.2292C35.1269 38 36.6667 36.3203 36.6667 34.25V11.75C36.6667 9.67969 35.1269 8 33.2292 8ZM13.75 5.5H22.9167V8H13.75V5.5ZM25.2083 24.875C25.2083 25.2188 24.9505 25.5 24.6354 25.5H20.625V29.875C20.625 30.2188 20.3672 30.5 20.0521 30.5H16.6146C16.2995 30.5 16.0417 30.2188 16.0417 29.875V25.5H12.0312C11.7161 25.5 11.4583 25.2188 11.4583 24.875V21.125C11.4583 20.7812 11.7161 20.5 12.0312 20.5H16.0417V16.125C16.0417 15.7812 16.2995 15.5 16.6146 15.5H20.0521C20.3672 15.5 20.625 15.7812 20.625 16.125V20.5H24.6354C24.9505 20.5 25.2083 20.7812 25.2083 21.125V24.875Z"
                    fill="black"
                  />
                </svg>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="customer-icon-heading margin-top20px-under-767px flexibility-heading-top-space">
                 Check Your Health
                </div>
                <div className="customer-icon-heading-text take-on-new-top-space">
                If you’re feeling sick or unwell (for example, you have a cough, fever, or fatigue), you must reschedule your appointment for a later date & book again once you feel better.
                </div>
              </div>
            </div>
           
           <div
              className="col-lg-4 py-3 my-lg-0 my-3 d-flex flex-column align-items-center overflow-y-hidden"
              align="center"
            >
              <div className="row over-x-unset">
                <div className="covid-19-door-bg">
                <svg
                  width="37"
                  height="40"
                  viewBox="0 0 37 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="covid-19-icon-top-space"
                >
                  <path
                    d="M32.0833 5H24.4444C24.4444 2.24219 21.704 0 18.3333 0C14.9627 0 12.2222 2.24219 12.2222 5H4.58333C2.05295 5 0 6.67969 0 8.75V36.25C0 38.3203 2.05295 40 4.58333 40H32.0833C34.6137 40 36.6667 38.3203 36.6667 36.25V8.75C36.6667 6.67969 34.6137 5 32.0833 5ZM9.16667 33.125C7.8967 33.125 6.875 32.2891 6.875 31.25C6.875 30.2109 7.8967 29.375 9.16667 29.375C10.4366 29.375 11.4583 30.2109 11.4583 31.25C11.4583 32.2891 10.4366 33.125 9.16667 33.125ZM9.16667 25.625C7.8967 25.625 6.875 24.7891 6.875 23.75C6.875 22.7109 7.8967 21.875 9.16667 21.875C10.4366 21.875 11.4583 22.7109 11.4583 23.75C11.4583 24.7891 10.4366 25.625 9.16667 25.625ZM9.16667 18.125C7.8967 18.125 6.875 17.2891 6.875 16.25C6.875 15.2109 7.8967 14.375 9.16667 14.375C10.4366 14.375 11.4583 15.2109 11.4583 16.25C11.4583 17.2891 10.4366 18.125 9.16667 18.125ZM18.3333 3.125C19.6033 3.125 20.625 3.96094 20.625 5C20.625 6.03906 19.6033 6.875 18.3333 6.875C17.0634 6.875 16.0417 6.03906 16.0417 5C16.0417 3.96094 17.0634 3.125 18.3333 3.125ZM30.5556 31.875C30.5556 32.2188 30.2118 32.5 29.7917 32.5H16.0417C15.6215 32.5 15.2778 32.2188 15.2778 31.875V30.625C15.2778 30.2812 15.6215 30 16.0417 30H29.7917C30.2118 30 30.5556 30.2812 30.5556 30.625V31.875ZM30.5556 24.375C30.5556 24.7188 30.2118 25 29.7917 25H16.0417C15.6215 25 15.2778 24.7188 15.2778 24.375V23.125C15.2778 22.7812 15.6215 22.5 16.0417 22.5H29.7917C30.2118 22.5 30.5556 22.7812 30.5556 23.125V24.375ZM30.5556 16.875C30.5556 17.2188 30.2118 17.5 29.7917 17.5H16.0417C15.6215 17.5 15.2778 17.2188 15.2778 16.875V15.625C15.2778 15.2812 15.6215 15 16.0417 15H29.7917C30.2118 15 30.5556 15.2812 30.5556 15.625V16.875Z"
                    fill="black"
                  />
                </svg>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="customer-icon-heading margin-top20px-under-767px flexibility-heading-top-space">
                  Positive Test Results
                </div>
                <div className="customer-icon-heading-text take-on-new-top-space">
                If you are COVID-19 positive, you must wait 20 days from the date you took the test to book your service. This way, our stylists and customers are always kept as safe as possible.
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 py-3 my-lg-0 my-3 d-flex flex-column align-items-center overflow-y-hidden"
              align="center"
            >
              <div className="row over-x-unset">
                <div className="covid-19-door-bg">
                <svg
                  width="49"
                  height="28"
                  viewBox="0 0 49 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="covid-19-icon-top-space"

                >
                  <path
                    d="M47.1885 8.92577C46.5597 8.78603 45.9309 9.20524 45.7911 9.83406C45.1623 12.6987 42.5771 16.0524 40.6907 18.1485V6.06113C41.1099 5.36244 42.2278 3.8952 43.3457 3.8952C43.9745 3.8952 44.5335 3.33624 44.5335 2.70742C44.5335 2.0786 43.9745 1.51965 43.3457 1.51965C41.2496 1.51965 39.7125 3.26637 39.0138 4.38428L24.4112 0.0524018C24.2016 -0.0174672 23.9221 -0.0174672 23.7125 0.0524018L9.1099 4.38428C8.34134 3.26637 6.87409 1.58952 4.77802 1.51965C4.1492 1.51965 3.59025 2.00873 3.59025 2.70742C3.59025 3.40611 4.07933 3.8952 4.77802 3.8952C5.89592 3.8952 7.01383 5.36244 7.43304 6.06113V18.1485C5.54658 16.0524 2.96142 12.6987 2.3326 9.83406C2.19287 9.20524 1.56404 8.78603 0.935224 8.92577C0.306403 9.0655 -0.112811 9.69432 0.0269266 10.3231C1.2147 15.6332 7.50291 21.7118 7.78239 21.9214C7.92212 21.9913 15.1885 28 24.6907 28C29.5116 28 34.8916 26.4629 40.2016 21.9214C40.4811 21.6419 46.7693 15.6332 47.9571 10.3231C48.1667 9.69432 47.8173 9.0655 47.1885 8.92577ZM12.813 10.0437H35.171C35.7999 10.0437 36.3588 10.6026 36.3588 11.2314C36.3588 11.8603 35.7999 12.4192 35.171 12.4192H12.813C12.1841 12.4192 11.6252 11.8603 11.6252 11.2314C11.6252 10.6026 12.1143 10.0437 12.813 10.0437ZM11.695 14.1659C11.9047 13.5371 12.6033 13.2576 13.2322 13.5371C13.3719 13.607 25.3894 18.2882 34.7518 13.607C35.3108 13.3275 36.0095 13.5371 36.3588 14.0961C36.6383 14.655 36.4287 15.3537 35.8697 15.7031C32.3064 17.5197 28.5335 18.0786 25.04 18.0786C18.3326 18.0786 12.7431 15.9127 12.3937 15.7729C11.7649 15.4935 11.4854 14.7948 11.695 14.1659ZM35.9396 19.476C31.9571 22.2707 28.0444 23.179 24.4811 23.179C17.4942 23.179 12.1841 19.5459 12.0444 19.476C11.4854 19.1266 11.4156 18.3581 11.7649 17.7991C12.1143 17.2402 12.8828 17.1703 13.3719 17.5197C13.7911 17.7991 24.1317 24.8559 34.5422 17.5197C35.1012 17.1703 35.7999 17.2402 36.2191 17.7991C36.6383 18.3581 36.4985 19.0568 35.9396 19.476Z"
                    fill="black"
                  />
                </svg>
                  </div>
              </div>
              <div className="col-lg-12">
                <div className="customer-icon-heading margin-top20px-under-767px flexibility-heading-top-space">Wear Your Mask</div>
                <div className="customer-icon-heading-text take-on-new-top-space">
                We require you & hair stylist 
to wear a mask during the appointment.  If your stylist doesn’t agree to wear one, you reserve the right to cancel the appointment.
                </div>
              </div>
            </div>
         <div class="row margin-top-60px-for-row-stylist">
         <div
              className="col-lg-4 py-3 my-lg-0 my-3 d-flex flex-column align-items-center overflow-y-hidden"
              align="center"
            >
              <div className="row over-x-unset">
                <div className="covid-19-door-bg">
                <svg
                  width="37"
                  height="40"
                  viewBox="0 0 37 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="covid-19-icon-top-space"
                >
                  <path
                    d="M29.7917 0C26.0033 0 22.9167 3.36719 22.9167 7.5C22.9167 11.6328 26.0033 15 29.7917 15C33.5801 15 36.6667 11.6328 36.6667 7.5C36.6667 3.36719 33.5801 0 29.7917 0ZM29.7917 10C28.5241 10 27.5 8.88281 27.5 7.5C27.5 6.11719 28.5241 5 29.7917 5C31.0592 5 32.0833 6.11719 32.0833 7.5C32.0833 8.88281 31.0592 10 29.7917 10ZM18.3333 8.75C18.3333 3.91406 14.7454 0 10.3125 0C5.87956 0 2.29167 3.91406 2.29167 8.75V21.7578C0.880859 23.6875 0 26.0938 0 28.75C0 34.9609 4.61914 40 10.3125 40C16.0059 40 20.625 34.9609 20.625 28.75C20.625 26.0938 19.7441 23.6797 18.3333 21.7578V8.75ZM10.3125 35C7.1543 35 4.58333 32.1953 4.58333 28.75C4.58333 26.7578 5.45703 24.9297 6.875 23.7656V8.75C6.875 6.67969 8.41471 5 10.3125 5C12.2103 5 13.75 6.67969 13.75 8.75V23.7656C15.168 24.9219 16.0417 26.7578 16.0417 28.75C16.0417 32.1953 13.4707 35 10.3125 35ZM11.4583 25.2266V23.75C11.4583 23.0625 10.9427 22.5 10.3125 22.5C9.68229 22.5 9.16667 23.0625 9.16667 23.75V25.2266C7.83463 25.7422 6.875 27.1172 6.875 28.75C6.875 30.8203 8.41471 32.5 10.3125 32.5C12.2103 32.5 13.75 30.8203 13.75 28.75C13.75 27.1172 12.7904 25.7422 11.4583 25.2266Z"
                    fill="black"
                  />
                </svg>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="customer-icon-heading margin-top20px-under-767px  flexibility-heading-top-space">
                  Temperature Check
                </div>
                <div className="customer-icon-heading-text take-on-new-top-space">
                Hair stylist will check yours & their own temperature when they arrive and share the temperature reading before starting the appointment.
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 py-3 my-lg-0 my-3 d-flex flex-column align-items-center overflow-y-hidden"
              align="center"
            >
              <div className="row over-x-unset">
                <div className="covid-19-door-bg">
                <svg
                  width="37"
                  height="40"
                  viewBox="0 0 37 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="covid-19-icon-top-space"
                >
                  <path
                    d="M16.0417 2.5C16.0417 1.11953 15.0154 0 13.75 0H9.16667C7.90124 0 6.875 1.11953 6.875 2.5V10H16.0417V2.5ZM34.375 10C33.1096 10 32.0833 11.1195 32.0833 12.5C32.0833 13.8805 33.1096 15 34.375 15C35.6404 15 36.6667 13.8805 36.6667 12.5C36.6667 11.1195 35.6404 10 34.375 10ZM16.0417 12.5H6.875C3.07799 12.5 0 15.8578 0 20V37.5C0 38.8805 1.02624 40 2.29167 40H20.625C21.8904 40 22.9167 38.8805 22.9167 37.5V20C22.9167 15.8578 19.8387 12.5 16.0417 12.5ZM11.4583 32.5C8.2944 32.5 5.72917 29.7016 5.72917 26.25C5.72917 22.7984 8.2944 20 11.4583 20C14.6223 20 17.1875 22.7984 17.1875 26.25C17.1875 29.7016 14.6223 32.5 11.4583 32.5ZM34.375 7.5C35.6404 7.5 36.6667 6.38047 36.6667 5C36.6667 3.61953 35.6404 2.5 34.375 2.5C33.1096 2.5 32.0833 3.61953 32.0833 5C32.0833 6.38047 33.1096 7.5 34.375 7.5ZM27.5 10C26.2346 10 25.2083 11.1195 25.2083 12.5C25.2083 13.8805 26.2346 15 27.5 15C28.7654 15 29.7917 13.8805 29.7917 12.5C29.7917 11.1195 28.7654 10 27.5 10ZM20.625 2.5C19.3596 2.5 18.3333 3.61953 18.3333 5C18.3333 6.38047 19.3596 7.5 20.625 7.5C21.8904 7.5 22.9167 6.38047 22.9167 5C22.9167 3.61953 21.8904 2.5 20.625 2.5ZM27.5 2.5C26.2346 2.5 25.2083 3.61953 25.2083 5C25.2083 6.38047 26.2346 7.5 27.5 7.5C28.7654 7.5 29.7917 6.38047 29.7917 5C29.7917 3.61953 28.7654 2.5 27.5 2.5ZM34.375 17.5C33.1096 17.5 32.0833 18.6195 32.0833 20C32.0833 21.3805 33.1096 22.5 34.375 22.5C35.6404 22.5 36.6667 21.3805 36.6667 20C36.6667 18.6195 35.6404 17.5 34.375 17.5Z"
                    fill="black"
                  />
                </svg>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="customer-icon-heading margin-top20px-under-767px flexibility-heading-top-space">
                  Pre & Post Sanitization
                </div>
                <div className="customer-icon-heading-text take-on-new-top-space">
                Your hair stylist will sanitize all the equipment for creating a safer workspace. Stylists will also carry single-use disposable capes.
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 py-3 my-lg-0 my-3 d-flex flex-column align-items-center overflow-y-hidden"
              align="center"
            >
              <div className="row over-x-unset">
                <div className="covid-19-door-bg">
                <svg
                  width="37"
                  height="36"
                  viewBox="0 0 37 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="covid-19-icon-top-space"
                >
                  <path
                    d="M11.222 18H1.14583C0.515625 18 0 18.5625 0 19.25V21.75C0 22.4375 0.515625 23 1.14583 23H11.3294C12.4681 23 13.5352 23.8515 13.7214 25.0781C13.9577 26.6406 12.8548 28 11.4583 28C10.4486 28 9.58919 27.2812 9.28125 26.289C9.13086 25.7968 8.66536 25.5 8.19271 25.5H5.84375C5.14193 25.5 4.57617 26.1875 4.70508 26.9375C5.32096 30.3828 8.11393 33 11.4512 33C15.5404 33 18.806 29.0859 18.2689 24.5156C17.832 20.7343 14.7096 18 11.222 18ZM1.14583 15.5H25.2083C29.4837 15.5 32.8568 11.2187 31.9258 6.38277C31.3815 3.55464 29.2832 1.26558 26.6908 0.671829C22.7233 -0.234421 19.1641 2.61714 18.4622 6.57027C18.3262 7.32027 18.8991 7.99995 19.5938 7.99995H21.9427C22.4225 7.99995 22.8809 7.70308 23.0312 7.21089C23.3392 6.2187 24.1986 5.49995 25.2083 5.49995C26.5977 5.49995 27.7077 6.85933 27.4714 8.42183C27.2852 9.64839 26.2253 10.5 25.0794 10.5H1.14583C0.515625 10.5 0 11.0625 0 11.75V14.25C0 14.9375 0.515625 15.5 1.14583 15.5ZM28.6458 18H17.4525C18.8346 19.2968 19.8301 21.0312 20.3027 23H28.6458C30.5436 23 32.0833 24.6796 32.0833 26.75C32.0833 28.8203 30.5436 30.5 28.6458 30.5C27.3639 30.5 26.2611 29.7265 25.6667 28.5937C25.459 28.2031 25.0436 28 24.6283 28H22.2077C21.4271 28 20.847 28.8437 21.112 29.6484C22.3867 33.6015 26.1608 36.2734 30.3789 35.2968C33.3294 34.6171 35.7572 32.0468 36.4447 28.8359C37.6693 23.1171 33.6947 18 28.6458 18Z"
                    fill="black"
                  />
                </svg>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="customer-icon-heading margin-top20px-under-767px flexibility-heading-top-space">
                  Good Ventilation Matters
                </div>
                <div className="customer-icon-heading-text take-on-new-top-space">
                We recommend choosing a place in the home that provides good ventilation, such as backyards and garages which are naturally ventilated.
                </div>
              </div>
            </div>
          
         </div>
         </div>
        </div>
      </div>
    </div>
  );
};

export default Standard;
