import React from "react";
import { FiArrowRight } from "react-icons/fi";
import "../../styles/stylist/stylist.css";
import Career from "./stylist_container/career";
import Customerlove from "./stylist_container/customerlove";
import StylistJoin from "./stylist_container/stylistJoin";
import StylistFAQ from "./stylist_container/stylistfaq";
import StylistForm from "./stylist_container/StylistForm";
// malchimp
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { animateScroll } from "react-scroll";

const Stylist = () => {

  const scrolldown = () => {
    animateScroll.scrollToBottom()
  }

  const MAILCHIMP_URL = 'https://instacuts.us6.list-manage.com/subscribe/post?u=41a72be870dc74caac6ddf53d&amp;id=579d3cb8f5'
  return (
    <div className="mt-5 pt-5" >
      <div className="chhose-book-and-get-container stylist-top-space">
        <div className="row">
          <div className="col-md-6 col-xl-7">
            <p className="stylist-header-heading stylist-heading">
              Join<br /> Instacuts!
            </p>
            <p className="stylist-main-desc Heres-an-right-padding">
              Here’s an opportunity to thrive without constant oversight and limitations! Enjoy the freedom that comes with being an Independent hair stylist and take charge of your own clientele base.
            </p>
            <div className="d-flex justify-content-center">
              <div className="col-lg-12  justify-content-center align-items-center">
                <img
                  src="assets/stylistpage/undraw_Mobile_life_re_jtih 1.png"
                  alt=""
                  className="w-100 postition-absolute stylist-iamge"
                />
                <div className="position-absolute justify-content-center stylist-cng-subimg-1 stylist-cng-subimg-3 left-40-for-top-notification over-x-unset">
                  <div className="d-flex shadow justify-content-center align-items-center p-md-2">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="d-flex justify-content-center">
                        <div className="col-lg-12 d-flex align-items-center">
                          <svg
                            width="37"
                            height="36"
                            viewBox="0 0 29 28"
                            fill="none"
                          >
                            <circle
                              cx="14.5932"
                              cy="13.9999"
                              r="13.7143"
                              fill="black"
                            />
                            <path
                              d="M19.8187 17.6618C19.8061 17.6445 18.5491 15.9051 18.5491 14.4624V11.8862C18.5491 9.75206 16.7779 8.02197 14.5931 8.02197C12.4082 8.02197 10.6371 9.75206 10.6371 11.8862V14.4624C10.6371 15.9051 9.3801 17.6445 9.36744 17.6618C9.31005 17.7403 9.3024 17.8436 9.34761 17.9294C9.39283 18.0152 9.48334 18.0691 9.58212 18.0691H19.604C19.7028 18.0691 19.7933 18.0152 19.8386 17.9294C19.8838 17.8436 19.8761 17.7403 19.8187 17.6618Z"
                              fill="white"
                            />
                            <path
                              d="M14.845 20.0786C15.5907 20.077 16.2271 19.4406 16.352 18.5715H13.3379C13.4628 19.4406 14.0992 20.077 14.845 20.0786Z"
                              fill="white"
                            />
                          </svg>
                          <div className="d-flex px-2 flex-column stylist-cng-subimg-details-1 text-left">
                            <span>New Order!</span>

                            <span>Order from Samantha William</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-5 form-side-top-space">
            <div className="row">
              <div className="col-6 overflow-y-hidden">
                <h4 className="stylist-header-form-title overflow-y-hidden">
                  Become a stylist
                </h4>
              </div>
              <div className="col-6 text-right">
                <a href="#/" className="stylist-header-form-reg-btn" onClick={()=>scrolldown()}>
                  <p className="stylist-form-btn-text">
                    Registration with apps <FiArrowRight />
                  </p>
                </a>
              </div>
            </div>
            <MailchimpSubscribe
              url={MAILCHIMP_URL}
              render={({ subscribe, status, message }) => (
                <StylistForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                  scrolldown={scrolldown}
                />
              )}
            />
          </div>
        </div>
      </div>
      <StylistJoin />
      <Career />
          {/* <Customerlove className="over-x-unset" /> */}
      <StylistFAQ />
    </div>
  );
};

export default Stylist;
