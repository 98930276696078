import React from "react";

const StylistDrives = () => {
  return (
    <>
    
    <div className="chhose-book-and-get-container justify-content-center safety-is-our-key-priority-top-space">
        <div className="row  justify-content-center ">
          <div className="col-md-7 home-img-main-content">
            <img
              className="width-inherit"
              src="assets/safetypage/stylistsaftymid.png"
              alt=""
            />
          </div>
          <div className="col-md-5  d-flex justify-content-center align-items-center">
            <div className="row d-flex justify-content-center">
              <div className="col-md-12 safetyis-our-key-priority-right-padding">
                <span className="home-work-title d-lg-block overflow-y-hidden ">
                Your Safety Matters To Us
                </span>
                <p className="customer-icon-heading-text text-lg-left text-left both-the-safetytop-space"> Feedback from our stylists will help us constantly engage in ensuring safety standards and protocols.  Feel at ease knowing your health and safety is our priority.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="justify-content-center  chhose-book-and-get-container customer-safety-bottom-space-div">
        <div className="content">
          <div className="content-box d-flex justify-content-center">
            <div className="row w-100 d-flex justify-content-lg-center mb-5 py-lg-5 py-0">
              <div className="col-lg-4 col-md-6 mt-lg-3 mt-0 d-flex flex-column align-items-lg-start align-items-center">
                <div className="customer-box-common mt-xl-0 mt-md-4 mb-md-0 mb-0 d-flex align-items-center justify-content-center">
                  <div className="customer-box-image">
                    <svg
                      width="31"
                      height="29"
                      viewBox="0 0 31 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.131 17.8835C23.7533 18.2495 23.5798 18.7789 23.6658 19.2981L24.9623 26.4731C25.0717 27.0812 24.815 27.6966 24.306 28.0481C23.8073 28.4127 23.1437 28.4564 22.5998 28.1647L16.1408 24.796C15.9163 24.6764 15.6669 24.6122 15.4117 24.605H15.0165C14.8794 24.6254 14.7452 24.6691 14.6227 24.7362L8.16229 28.121C7.84292 28.2814 7.48125 28.3383 7.12688 28.2814C6.26354 28.1181 5.6875 27.2956 5.82896 26.4279L7.12688 19.2529C7.21292 18.7293 7.03938 18.197 6.66167 17.8252L1.39563 12.721C0.95521 12.2937 0.802085 11.652 1.00333 11.0731C1.19875 10.4956 1.6975 10.0741 2.29979 9.97933L9.54771 8.92787C10.099 8.871 10.5831 8.53558 10.831 8.03975L14.0248 1.49183C14.1006 1.346 14.1983 1.21183 14.3165 1.09808L14.4477 0.995996C14.5162 0.920163 14.595 0.857454 14.6825 0.806413L14.8415 0.748079L15.0894 0.645996H15.7033C16.2517 0.702871 16.7344 1.031 16.9867 1.521L20.2227 8.03975C20.456 8.51662 20.9096 8.84766 21.4331 8.92787L28.681 9.97933C29.2935 10.0668 29.8054 10.4897 30.0081 11.0731C30.1992 11.6579 30.0344 12.2995 29.5852 12.721L24.131 17.8835Z"
                        fill="#130F26"
                      />
                    </svg>
                  </div>
                </div>
                <div className="customer-way-text reviews-and-text-space flexibility-heading-top-space">Reviews & Ratings</div>
                <div className="customer-desc-way mt-2 mx-lg-0 mx-md-3 text-lg-left text-center take-on-new-top-space">
                Both stylists and clients can rate each other. If average ratings drop below
3, they will be temporarily blocked to verify the reason.
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mt-lg-3 mt-5 mt-md-0 d-flex flex-column align-items-lg-start align-items-center">
                <div className="customer-box-common mt-xl-0 mt-md-4 mb-md-0 mb-0 d-flex align-items-center justify-content-center">
                  <div className="customer-box-image2">
                    <svg
                      width="30"
                      height="34"
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.2044 9.32672V11.8823C27.075 12.7783 29.1666 15.3769 29.1666 18.4807V26.7089C29.1666 30.5513 25.981 33.6667 22.0536 33.6667H7.94792C4.01885 33.6667 0.833252 30.5513 0.833252 26.7089V18.4807C0.833252 15.3769 2.9265 12.7783 5.7954 11.8823V9.32672C5.81233 4.35802 9.92769 0.333374 14.9745 0.333374C20.0891 0.333374 24.2044 4.35802 24.2044 9.32672ZM15.0084 3.23176C18.4464 3.23176 21.2408 5.96454 21.2408 9.3267V11.5229H8.75919V9.29357C8.77613 5.94798 11.5705 3.23176 15.0084 3.23176ZM16.4818 24.4249C16.4818 25.2365 15.8213 25.8824 14.9914 25.8824C14.1785 25.8824 13.518 25.2365 13.518 24.4249V20.7481C13.518 19.9531 14.1785 19.3072 14.9914 19.3072C15.8213 19.3072 16.4818 19.9531 16.4818 20.7481V24.4249Z"
                        fill="#130F26"
                      />
                    </svg>
                  </div>
                </div>
                <div className="customer-way-text reviews-and-text-space text-md-left text-center flexibility-heading-top-space">
                Phone Number Masking
                </div>
                <div className="customer-desc-way mt-2 mx-lg-0 mx-md-3 text-lg-left text-center take-on-new-top-space">
                The safety of your personal information is very important to us, so rest
assured, your phone number won’t be shared with anyone.
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mt-lg-3 mt-5 d-flex flex-column align-items-lg-start align-items-center">
                <div className="customer-box-common mt-xl-0 mt-md-4 mb-md-0 mb-0 d-flex align-items-center justify-content-center">
                  <div className="customer-box-image">
                    <svg
                      width="34"
                      height="32"
                      viewBox="0 0 34 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M23.4164 0.167747C24.468 0.167747 25.518 0.31608 26.5164 0.65108C32.668 2.65108 34.8847 9.40108 33.033 15.3011C31.983 18.3161 30.2664 21.0677 28.018 23.3161C24.7997 26.4327 21.268 29.1994 17.4664 31.5828L17.0497 31.8344L16.6164 31.5661C12.8014 29.1994 9.2497 26.4328 6.00137 23.2994C3.76803 21.0511 2.0497 18.3161 0.983035 15.3011C-0.900298 9.40108 1.31637 2.65108 7.5347 0.61608C8.01803 0.449413 8.51637 0.332747 9.01637 0.267747H9.21637C9.6847 0.199413 10.1497 0.167747 10.6164 0.167747H10.7997C11.8497 0.199413 12.8664 0.382747 13.8514 0.717747H13.9497C14.0164 0.749413 14.0664 0.784413 14.0997 0.81608C14.468 0.934413 14.8164 1.06775 15.1497 1.25108L15.783 1.53441C15.9361 1.61603 16.1078 1.74075 16.2563 1.84853C16.3504 1.91682 16.4351 1.97832 16.4997 2.01775C16.5269 2.03379 16.5545 2.04992 16.5824 2.06619C16.7253 2.14961 16.8742 2.23651 16.9997 2.33275C18.8514 0.917747 21.0997 0.15108 23.4164 0.167747ZM27.8497 12.1677C28.533 12.1494 29.1164 11.6011 29.1664 10.8994V10.7011C29.2164 8.36608 27.8014 6.25108 25.6497 5.43441C24.9664 5.19941 24.2164 5.56774 23.9664 6.26774C23.733 6.96774 24.0997 7.73441 24.7997 7.98274C25.868 8.38274 26.583 9.43441 26.583 10.5994V10.6511C26.5514 11.0327 26.6664 11.4011 26.8997 11.6844C27.133 11.9677 27.483 12.1327 27.8497 12.1677Z"
                        fill="#130F26"
                      />
                    </svg>
                  </div>
                </div>
                <div className="customer-way-text reviews-and-text-space flexibility-heading-top-space">Share Your Status</div>
                <div className="customer-desc-way mt-2 mx-lg-0 mx-md-3 text-lg-left text-center take-on-new-top-space">
                Add another layer of safety by making sure your loved ones know
when you are getting services at home.
                </div>
              </div>
              <div class="row margin-top-60px-for-row-stylist">
              <div className="col-lg-4 col-md-6 mt-lg-3 mt-5 d-flex flex-column align-items-lg-start align-items-center">
                <div className="customer-box-common mt-xl-0 mt-md-4 mb-md-0 mb-0 d-flex align-items-center justify-content-center">
                  <div className="customer-box-image">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.7835 0.33313H24.2335C29.8835 0.33313 33.6668 4.2998 33.6668 10.1998V23.8181C33.6668 29.6998 29.8835 33.6665 24.2335 33.6665H9.7835C4.1335 33.6665 0.333496 29.6998 0.333496 23.8181V10.1998C0.333496 4.2998 4.1335 0.33313 9.7835 0.33313ZM16.0501 21.9831L23.9668 14.0665C24.5335 13.4998 24.5335 12.5831 23.9668 11.9998C23.4001 11.4331 22.4668 11.4331 21.9001 11.9998L15.0168 18.8831L12.1001 15.9665C11.5335 15.3998 10.6001 15.3998 10.0335 15.9665C9.46679 16.5331 9.46679 17.4498 10.0335 18.0331L14.0001 21.9831C14.2835 22.2665 14.6501 22.3998 15.0168 22.3998C15.4001 22.3998 15.7668 22.2665 16.0501 21.9831Z"
                        fill="#130F26"
                      />
                    </svg>
                  </div>
                </div>
                <div className="customer-way-text reviews-and-text-space flexibility-heading-top-space">Full Control</div>
                <div className="customer-desc-way mt-2 mx-lg-0 mx-md-3 text-lg-left text-center take-on-new-top-space">
                On our platform you are in control and can handpick stylists or block
them when you please. 
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mt-lg-3 mt-5 d-flex flex-column align-items-lg-start align-items-center">
                <div className="customer-box-common mt-xl-0 mt-md-4 mb-md-0 mb-0 d-flex align-items-center justify-content-center">
                  <div className="customer-box-image">
                    <svg
                      width="30"
                      height="34"
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M26.1784 11.6606C26.1784 13.7538 26.7316 14.9876 27.9491 16.4094C28.8717 17.4568 29.1666 18.8014 29.1666 20.2601C29.1666 21.7171 28.6879 23.1003 27.7288 24.2233C26.4732 25.5695 24.7025 26.4289 22.8953 26.5783C20.2765 26.8016 17.656 26.9896 15.0007 26.9896C12.3438 26.9896 9.725 26.8771 7.10617 26.5783C5.29735 26.4289 3.52662 25.5695 2.27269 24.2233C1.31362 23.1003 0.833252 21.7171 0.833252 20.2601C0.833252 18.8014 1.12975 17.4568 2.05073 16.4094C3.30631 14.9876 3.82312 13.7538 3.82312 11.6606V10.9505C3.82312 8.14727 4.52214 6.31424 5.96158 4.51981C8.10169 1.90287 11.5322 0.333374 14.9262 0.333374H15.0753C18.5422 0.333374 22.0837 1.97841 24.1873 4.70781C25.5522 6.46531 26.1784 8.22113 26.1784 10.9505V11.6606ZM10.1227 30.4347C10.1227 29.5954 10.893 29.211 11.6053 29.0465C12.4384 28.8703 17.5154 28.8703 18.3486 29.0465C19.0609 29.211 19.8311 29.5954 19.8311 30.4347C19.7897 31.2338 19.3209 31.9421 18.6733 32.392C17.8335 33.0466 16.8479 33.4613 15.8176 33.6107C15.2478 33.6845 14.6879 33.6862 14.1379 33.6107C13.106 33.4613 12.1204 33.0466 11.2823 32.3903C10.6329 31.9421 10.1642 31.2338 10.1227 30.4347Z"
                        fill="#130F26"
                      />
                    </svg>
                  </div>
                </div>
                <div className="customer-way-text reviews-and-text-space text-md-left text-center flexibility-heading-top-space">
                  Safety button
                </div>
                <div className="customer-desc-way mt-2 mx-lg-0 mx-md-3 text-lg-left text-center take-on-new-top-space">
                Our apps include an emergency button that will provide you with
immediate assistance whenever you need it.
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mt-lg-3 mt-5 d-flex flex-column align-items-lg-start align-items-center">
                <div className="customer-box-common mt-xl-0 mt-md-4 mb-md-0 mb-0 d-flex align-items-center justify-content-center">
                  <div className="customer-box-image">
                    <svg width="30" height="34" viewBox="0 0 30 34" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5465 33.5517C14.7311 33.6482 14.9374 33.6972 15.1438 33.6956C15.3502 33.6939 15.5549 33.6433 15.7412 33.5452L21.6876 30.3664C23.3739 29.4675 24.6944 28.4624 25.7246 27.2922C27.9646 24.7426 29.1878 21.4887 29.166 18.1334L29.0955 7.06561C29.0888 5.79082 28.2516 4.65332 27.0133 4.23983L15.9509 0.528245C15.2848 0.302706 14.5549 0.307609 13.9005 0.539685L2.88008 4.38366C1.64851 4.81349 0.826337 5.95916 0.833049 7.23558L0.90352 18.2952C0.925333 21.6554 2.19047 24.8946 4.46737 27.4181C5.50767 28.5719 6.83992 29.5623 8.54466 30.4465L14.5465 33.5517ZM12.9723 20.5439C13.2206 20.7825 13.5428 20.9001 13.865 20.8969C14.1871 20.8952 14.5076 20.7743 14.7526 20.5324L21.2511 14.1258C21.7393 13.6437 21.7343 12.869 21.241 12.3934C20.746 11.9178 19.949 11.9211 19.4607 12.4032L13.8465 17.9371L11.5478 15.7275C11.0528 15.2519 10.2575 15.2568 9.76753 15.7389C9.27926 16.221 9.2843 16.9957 9.77928 17.4713L12.9723 20.5439Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="customer-way-text reviews-and-text-space flexibility-heading-top-space">Stay Safe Steps</div>
                <div className="customer-desc-way mt-2 mx-lg-0 mx-md-3 text-lg-left text-center take-on-new-top-space">
                Take a look at our safety FAQs section to learn about our safety features and what you need to do in different scenarios to remain safe.
                </div>
              </div>
            
              </div>
           </div>
          </div>
        </div>
      </div>
     </>
  );
};

export default StylistDrives;
