import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import Journy from "./safety_components/journy";
import "../../styles/safety/safety.css";
import Protocol from "./safety_components/protocol";
import Helping from "./safety_components/helping";

const Safety = () => {
  const scrolldown = () => {
    window.scrollTo({ behavior: "smooth", top: 900 });
  };
  return (
    <>
      <div className="mt-5 pt-5 safety">
        <div className="chhose-book-and-get-container-safety safety-top-sapce">
          <div className="content-imagecard justify-content-center">
            <div className="Row home-img-card  d-md-flex home-common align-items-center safety-containerr-inner-padding">
              <div className="col-md-6 d-flex flex-column justify-content-center">
                <span className="safety-heding-bottom-space safety-card-top-space-45px">
                  SAFETY
                </span>
                <span className="home-safety-card-title">
                  We are committed to your safety
                </span>
                <p className="home-imgcard-contents">
                  We take the safety of our clients and hair stylists very
                  seriously. In the same way that our hair stylists have become
                  mobile, so have our safety protocols.
                </p>
                <div className="">
                  <button
                    type="button"
                    onClick={() => scrolldown()}
                    className="btn home-btns home-header-btn px-4 d-flex align-items-center home-join-btns text-white"
                  >
                    LEARN MORE <BsArrowRightShort size="20px" />
                  </button>
                </div>
              </div>
              <div className="col-md-6 home-img-main-content safety-card-bottom-space-45px px-0">
                <img
                  className="home-img padding-top-for-20px-safety-image "
                  src="assets/safetypage/saftymainpng.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <Protocol />
        <Helping />
        <Journy />
      </div>
    </>
  );
};

export default Safety;
