import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import "../../styles/contactUs/contactUs.css";
import ContactForm from "./contact_component/contactform";

const ContactUs = () => {
  return (
    <>
      <div className="overflow-hidden">
        <div className="contact-content">
          <img
            src="assets/contactpage/contact_bg.png"
            alt=""
            className="contact-bg-img"
          />
          <div className="chhose-book-and-get-container here-to-help-you contact-centered  d-flex flex-column align-items-center">
            <span className="contact-main-title">We’re here to help</span>
            <div className="here-to-help-you-lorem-text">
              Our customer representatives work around the clock to answer your
              questions. Feel free to reach out if any questions arise.
            </div>
          </div>
        </div>
      </div>
      <div className="chhose-book-and-get-container-contact-us justify-content-center contact-card-content contact-us-card-position padding-horizantly-10px-767px">
        <div className="content-box over-x-unset">
          <div className="row">
            <div className="col-lg-4 p-lg-4">
              <div className="col-12 contact-card p-4 shadow p-3 mb-5 bg-white">
                <div className="">
                  <div className="d-flex justify-content-start">
                    <div className="d-flex align-items-center justify-content-center mb-4 home_work_icons shadow-none bg-light">
                      <svg
                        width="26"
                        height="30"
                        viewBox="0 0 26 30"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.6135 29.0445C12.7704 29.1265 12.9458 29.1681 13.1212 29.1668C13.2967 29.1654 13.4707 29.1223 13.629 29.039L18.6835 26.337C20.1168 25.5729 21.2392 24.7186 22.1149 23.7239C24.0189 21.5568 25.0586 18.7909 25.0401 15.9389L24.9802 6.5313C24.9745 5.44774 24.2628 4.48086 23.2103 4.1294L13.8072 0.974545C13.241 0.782837 12.6206 0.787005 12.0644 0.98427L2.69704 4.25164C1.6502 4.617 0.951361 5.59082 0.957066 6.67578L1.01697 16.0764C1.03551 18.9326 2.11087 21.686 4.04624 23.8309C4.93049 24.8117 6.06291 25.6535 7.51194 26.4051L12.6135 29.0445ZM11.2745 17.9878C11.4856 18.1906 11.7594 18.2906 12.0332 18.2879C12.3071 18.2865 12.5795 18.1837 12.7877 17.9781L18.3114 12.5324C18.7264 12.1226 18.7222 11.4642 18.3028 11.0599C17.8821 10.6556 17.2047 10.6584 16.7896 11.0682L12.0175 15.772L10.0636 13.8938C9.64289 13.4896 8.96686 13.4938 8.55041 13.9036C8.13538 14.3134 8.13966 14.9719 8.56039 15.3761L11.2745 17.9878Z"
                          fill="#200E32"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="contact-card-title">Support Ticket</div>
                <p className="contact-card-desc text-left">
                  For Technical Issues or App Functionality related support.
                </p>
                <button
                  type="button"
                  className="btn contact-card-btn text-white py-2 px-4 d-flex align-items-center my-4"
                >
                  CREATE TICKET <BsArrowRightShort size="20px" />
                </button>
              </div>
            </div>
            <div className="col-lg-4 p-lg-4">
              <div className="col-12 contact-card p-4 shadow p-3 mb-5 bg-white">
                <div className="">
                  <div className="d-flex justify-content-start">
                    <div className="d-flex align-items-center justify-content-center mb-4 home_work_icons shadow-none bg-light">
                      <svg
                        width="18"
                        height="26"
                        viewBox="0 0 18 26"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.85019 2.3801L7.31366 7.62067C7.31366 8.5433 8.06738 9.29129 9.00055 9.29129C9.93217 9.29129 10.6874 8.5433 10.6874 7.62067L11.1509 2.3801C11.1509 1.20402 10.1881 0.250488 9.00055 0.250488C7.81302 0.250488 6.85019 1.20402 6.85019 2.3801ZM7.22935 25.062C7.30904 25.1346 7.37015 25.1902 7.39893 25.2187C7.8546 25.5664 8.41169 25.7504 9.01092 25.7504C9.54773 25.7504 10.0845 25.5865 10.5184 25.2589C10.8195 25.0487 11.3033 24.5635 11.3033 24.5635C13.1837 22.8449 16.0128 18.5703 16.9226 16.402C16.9413 16.402 17.4797 15.1131 17.5 14.4996V14.4177C17.5 13.4765 16.9632 12.5971 16.0956 12.1474C15.7368 11.9616 14.8578 11.7873 14.4411 11.7046C14.3068 11.678 14.2205 11.6609 14.2152 11.656C12.9761 11.4721 11.0755 11.35 8.99064 11.35C6.79971 11.35 4.81788 11.4721 3.59914 11.6962C3.57885 11.6962 2.4631 11.9218 2.0917 12.0655C1.55489 12.2912 1.09923 12.6991 0.810538 13.2107C0.604553 13.6202 0.5 14.0499 0.5 14.4996C0.521847 14.9709 0.830824 15.8503 0.97595 16.198C1.88572 18.4883 4.85845 22.8666 6.67643 24.5434C6.86566 24.7308 7.08009 24.9261 7.22935 25.062Z"
                          fill="#200E32"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="contact-card-title">Drop in Us</div>
                <p className="contact-card-desc text-left">
                  Appointment only visits to Pickup Stylist’s Supplies.
                </p>
                <button
                  type="button"
                  className="btn contact-card-btn text-white py-2 px-4 d-flex align-items-center my-4"
                >
                  GET DIRECTION <BsArrowRightShort size="20px" />
                </button>
              </div>
            </div>
            <div className="col-lg-4 p-lg-4">
              <div className="col-12 contact-card p-4 shadow p-3 mb-5 bg-white">
                <div className="">
                  <div className=" d-flex justify-content-start">
                    <div className="d-flex align-items-center justify-content-center mb-4 home_work_icons shadow-none bg-light">
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          d="M24.7486 18.8676C24.0547 18.1576 21.0513 15.7702 20.3061 15.6139C19.4771 15.4389 18.8207 15.8202 18.2405 16.1539C17.6441 16.4976 17.1302 16.7951 16.4826 16.5601C13.1629 15.2052 10.7922 12.8352 9.44187 9.52901C9.20305 8.87152 9.49938 8.35528 9.84323 7.75904C10.1771 7.1803 10.5534 6.52281 10.3821 5.69407C10.2271 4.94908 7.84018 1.94663 7.12873 1.25289C6.6586 0.795399 6.17847 0.542903 5.68708 0.501653C3.83282 0.424155 1.54219 3.09411 1.17834 3.68035C0.268087 4.93408 0.274339 6.59781 1.19459 8.61777C3.4102 14.0427 11.88 22.5126 17.3691 24.795C18.3806 25.265 19.3071 25.5 20.1386 25.5C20.9538 25.5 21.6777 25.2738 22.3004 24.8263C22.7693 24.5588 25.5663 22.1863 25.4988 20.2976C25.4588 19.8151 25.2062 19.3351 24.7486 18.8676Z"
                          fill="#200E32"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="contact-card-title">Call any time</div>
                <p className="contact-card-desc text-left">
                  Available 24/7 to answer your questions. Feel free to give us
                  a call.
                </p>
                <button
                  type="button"
                  className="btn contact-card-btn text-white py-2 px-4 d-flex align-items-center my-4"
                >
                  CALL NOW <BsArrowRightShort size="20px" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </>
  );
};

export default ContactUs;
