import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { BsArrowRightShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const HomeFAQ = () => {
  const history = useHistory();
  const [activeFAQ, setActiveFAQ] = useState(null);

  const handleToggle = (i) => {
    activeFAQ === i ? setActiveFAQ(null) : setActiveFAQ(i);
  };

  return (
    <div className="align-items-center text-center customer-testimonials-top-space chhose-book-and-get-container p-3 p-md-4">
      <div className="">
        <span className="home-work-heading">Here’s</span>
      </div>
      <div className="frequently-asked-space">
        <span className="home-work-title2 ">Frequently Asked Questions</span>
      </div>

      <div className="">
        <Accordion>
          <div className="row d-lg-flex justify-content-center">
            <div className="col-xl-4 col-md-4 d-flex justify-content-center align-items-start">
              <div className="row w-100 d-flex flex-row">
                <div className="col-md-12 home-faq-title text-center mb-0">
                  APPS
                </div>
                <Card
                  className="w-100 text-left space-bw-cards home-faq-card-details"
                  style={{
                    backgroundColor: activeFAQ !== 0 ? "#fff" : "#EDF1F5",
                  }}
                >
                  <Accordion.Toggle
                    className="text-left"
                    as={Button}
                    variant=""
                    eventKey="0"
                    onClick={() => handleToggle(0)}
                  >
                    <div className="row d-flex py-2 justify-content-between align-items-center home-faq-extend-title faq-card-height home-faq-heading-button-padding">
                      <div className="col-9 col-md-11 col-xl-9 faq-fz-12">
                        Can I book appointments for multiple people at the same
                        time?
                      </div>
                      <div className="col-3 col-md-1 col-xl-3 d-flex justify-content-center align-items-center faq-icon-button collapse-button-faq over-x-unset">
                        <button
                          type="button"
                          className="home-faq-btn border-0 px-3 d-flex justify-content-center align-items-center"
                        >
                          {activeFAQ === 3 ? (
                            <span>&#45;</span>
                          ) : (
                            <span>&#43;</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="col-12 mb-3 home-faq-details home-faq-show  padding-for-home-faq">
                      Yes. Our technology allows you to schedule multiple
                      appointments (based on the availability of professionals)
                      seamlessly through the app.
                    </div>
                  </Accordion.Collapse>
                </Card>
                <Card
                  className="w-100 text-left space-bw-cards home-faq-card-details"
                  style={{
                    backgroundColor: activeFAQ !== 1 ? "#fff" : "#EDF1F5",
                  }}
                >
                  <Accordion.Toggle
                    className="text-left"
                    as={Button}
                    variant=""
                    eventKey="1"
                    onClick={() => handleToggle(1)}
                  >
                    <div className="row d-flex py-2 justify-content-between align-items-center home-faq-extend-title faq-card-height home-faq-heading-button-padding">
                      <div className="col-9 col-md-11 col-xl-9 faq-fz-12">
                        How old do I have to be to register on the Instacuts
                        app?
                      </div>
                      <div className="col-3 col-md-1 col-xl-3 d-flex justify-content-center align-items-center faq-icon-button collapse-button-faq over-x-unset">
                        <button
                          type="button"
                          className="home-faq-btn border-0 px-3 d-flex justify-content-center align-items-center"
                        >
                          {activeFAQ === 1 ? (
                            <span>&#45;</span>
                          ) : (
                            <span>&#43;</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <div className="col-12 mb-3 home-faq-details home-faq-show  padding-for-home-faq">
                      You must be 18 years or older to make an account on the
                      app. You can add as many minors to your account. Our
                      clients enjoy the stress-free delivery of a professional
                      hair cut at home for their kids
                    </div>
                  </Accordion.Collapse>
                </Card>
                <Card
                  className="w-100 text-left space-bw-cards home-faq-card-details"
                  style={{
                    backgroundColor: activeFAQ !== 2 ? "#fff" : "#EDF1F5",
                  }}
                >
                  <Accordion.Toggle
                    className="text-left"
                    as={Button}
                    variant=""
                    eventKey="2"
                    onClick={() => handleToggle(2)}
                  >
                    <div className="row d-flex py-2 justify-content-between align-items-center home-faq-extend-title faq-card-height home-faq-heading-button-padding">
                      <div className="col-9 col-md-11 col-xl-9 faq-fz-12">
                        How do I cancel an appointment?
                      </div>
                      <div className="col-3 col-md-1 col-xl-3 d-flex justify-content-center align-items-center faq-icon-button collapse-button-faq over-x-unset">
                        <button
                          type="button"
                          className="home-faq-btn border-0 px-3 d-flex justify-content-center align-items-center"
                        >
                          {activeFAQ === 2 ? (
                            <span>&#45;</span>
                          ) : (
                            <span>&#43;</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <div className="col-12 mb-3 home-faq-details home-faq-show  padding-for-home-faq">
                      You may cancel an appointment at any time. You will not be
                      charged for a cancellation unless you cancel less than an
                      hour from your appointment time.
                    </div>
                  </Accordion.Collapse>
                </Card>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 d-flex justify-content-center align-items-start">
              <div className="row w-100 d-flex flex-row">
                <div className="col-md-12 home-faq-title mb-0 faq-customer-text-top-space-under-425">
                  CUSTOMERS
                </div>

                <Card
                  className="w-100 text-left space-bw-cards home-faq-card-details"
                  style={{
                    backgroundColor: activeFAQ !== 3 ? "#fff" : "#EDF1F5",
                  }}
                >
                  <Accordion.Toggle
                    className="text-left"
                    as={Button}
                    variant=""
                    eventKey="3"
                    onClick={() => handleToggle(3)}
                  >
                    <div className="row d-flex py-2 justify-content-between align-items-center home-faq-extend-title faq-card-height home-faq-heading-button-padding">
                      <div className="col-9 col-md-11 col-xl-9 faq-fz-12">
                        Who are the Instacuts stylists?
                      </div>
                      <div className="col-3 col-md-1 col-xl-3 d-flex justify-content-center align-items-center faq-icon-button collapse-button-faq over-x-unset">
                        <button
                          type="button"
                          className="home-faq-btn border-0 px-3 d-flex justify-content-center align-items-center"
                        >
                          {activeFAQ === 3 ? (
                            <span>&#45;</span>
                          ) : (
                            <span>&#43;</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <div className="col-12 mb-3 home-faq-details home-faq-show  padding-for-home-faq">
                      Our professionals represent the best and the brightest
                      group of cosmetologists from all over the country. Each
                      stylist undergoes a background check and is carefully
                      selected based on expertise. Our clients boast about the
                      fabulous experience they have with our stylists
                    </div>
                  </Accordion.Collapse>
                </Card>
                <Card
                  className="w-100 text-left space-bw-cards home-faq-card-details"
                  style={{
                    backgroundColor: activeFAQ !== 4 ? "#fff" : "#EDF1F5",
                  }}
                >
                  <Accordion.Toggle
                    className="text-left"
                    as={Button}
                    variant=""
                    eventKey="4"
                    onClick={() => handleToggle(4)}
                  >
                    <div className="row d-flex py-2 justify-content-between align-items-center home-faq-extend-title faq-card-height home-faq-heading-button-padding">
                      <div className="col-9 col-md-11 col-xl-9 faq-fz-12">
                        What do I need to do to prepare for a home haircutting
                        visit?
                      </div>
                      <div className="col-3 col-md-1 col-xl-3 d-flex justify-content-center align-items-center faq-icon-button collapse-button-faq over-x-unset">
                        <button
                          type="button"
                          className="home-faq-btn border-0 px-3 d-flex justify-content-center align-items-center"
                        >
                          {activeFAQ === 4 ? (
                            <span>&#45;</span>
                          ) : (
                            <span>&#43;</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <div className="col-12 mb-3 home-faq-details home-faq-show  padding-for-home-faq">
                      Just relax and choose a spot in or outside your home
                      (garage, patio, balcony) where you have a chair to sit.
                      Our stylists bring all the equipment and supplies
                      necessary to complete your appointment.
                    </div>
                  </Accordion.Collapse>
                </Card>
                <Card
                  className="w-100 text-left space-bw-cards home-faq-card-details"
                  style={{
                    backgroundColor: activeFAQ !== 5 ? "#fff" : "#EDF1F5",
                  }}
                >
                  <Accordion.Toggle
                    className="text-left"
                    as={Button}
                    variant=""
                    eventKey="5"
                    onClick={() => handleToggle(5)}
                  >
                    <div className="row d-flex py-2 justify-content-between align-items-center home-faq-extend-title faq-card-height home-faq-heading-button-padding">
                      <div className="col-9 col-md-11 col-xl-9 faq-fz-12">
                        Who cleans up after the service?
                      </div>
                      <div className="col-3 col-md-1 col-xl-3 d-flex justify-content-center align-items-center faq-icon-button collapse-button-faq over-x-unset">
                        <button
                          type="button"
                          className="home-faq-btn border-0 px-3 d-flex justify-content-center align-items-center"
                        >
                          {activeFAQ === 5 ? (
                            <span>&#45;</span>
                          ) : (
                            <span>&#43;</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <div className="col-12 mb-3 home-faq-details home-faq-show  padding-for-home-faq">
                      Instacuts will be responsible for your clean-up. Our
                      stylists use drop cloths and clean up the mess before they
                      leave. &nbsp; It doesn’t get better than that.
                    </div>
                  </Accordion.Collapse>
                </Card>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 d-flex justify-content-center align-items-start">
              <div className="row w-100 d-flex flex-row">
                <div className="col-md-12 home-faq-title mb-0 faq-customer-text-top-space-under-425">
                  STYLIST
                </div>

                <Card
                  className="w-100 text-left space-bw-cards home-faq-card-details"
                  style={{
                    backgroundColor: activeFAQ !== 6 ? "#fff" : "#EDF1F5",
                  }}
                >
                  <Accordion.Toggle
                    className="text-left"
                    as={Button}
                    variant=""
                    eventKey="6"
                    onClick={() => handleToggle(6)}
                  >
                    <div className="row d-flex py-2 justify-content-between align-items-center home-faq-extend-title faq-card-height home-faq-heading-button-padding">
                      <div className="col-9 col-md-11 col-xl-9 faq-fz-12">
                        How do I receive notifications for an appointment?
                      </div>
                      <div className="col-3 col-md-1 col-xl-3 d-flex justify-content-center align-items-center faq-icon-button collapse-button-faq over-x-unset">
                        <button
                          type="button"
                          className="home-faq-btn border-0 px-3 d-flex justify-content-center align-items-center"
                        >
                          {activeFAQ === 6 ? (
                            <span>&#45;</span>
                          ) : (
                            <span>&#43;</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="6">
                    <div className="col-12 mb-3 home-faq-details home-faq-show  padding-for-home-faq">
                      Once you are onboarded, the app explains the process of
                      receiving and booking appointments.
                    </div>
                  </Accordion.Collapse>
                </Card>
                <Card
                  className="w-100 text-left space-bw-cards home-faq-card-details"
                  style={{
                    backgroundColor: activeFAQ !== 7 ? "#fff" : "#EDF1F5",
                  }}
                >
                  <Accordion.Toggle
                    className="text-left"
                    as={Button}
                    variant=""
                    eventKey="7"
                    onClick={() => handleToggle(7)}
                  >
                    <div className="row d-flex py-2 justify-content-between align-items-center home-faq-extend-title faq-card-height home-faq-heading-button-padding">
                      <div className="col-9 col-md-11 col-xl-9 faq-fz-12">
                        Do I need my own equipment?
                      </div>
                      <div className="col-3 col-md-1 col-xl-3 d-flex justify-content-center align-items-center faq-icon-button collapse-button-faq over-x-unset">
                        <button
                          type="button"
                          className="home-faq-btn border-0 px-3 d-flex justify-content-center align-items-center"
                        >
                          {activeFAQ === 7 ? (
                            <span>&#45;</span>
                          ) : (
                            <span>&#43;</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="7">
                    <div className="col-12 mb-3 home-faq-details home-faq-show  padding-for-home-faq">
                      Yes, each stylist should have their own haircutting and
                      grooming supplies. However, Instacuts will provide
                      stylists with a customer cape, stylist smock, drop cloth,
                      and sterilization and clean-up supplies
                    </div>
                  </Accordion.Collapse>
                </Card>
                <Card
                  className="w-100 text-left space-bw-cards home-faq-card-details"
                  style={{
                    backgroundColor: activeFAQ !== 8 ? "#fff" : "#EDF1F5",
                  }}
                >
                  <Accordion.Toggle
                    className="text-left"
                    as={Button}
                    variant=""
                    eventKey="8"
                    onClick={() => handleToggle(8)}
                  >
                    <div className="row d-flex py-2 justify-content-between align-items-center home-faq-extend-title faq-card-height home-faq-heading-button-padding">
                      <div className="col-9 col-md-11 col-xl-9 faq-fz-12">
                        How do I earn Reward Points?
                      </div>
                      <div className="col-3 col-md-1 col-xl-3 d-flex justify-content-center align-items-center faq-icon-button collapse-button-faq over-x-unset">
                        <button
                          type="button"
                          className="home-faq-btn border-0 px-3 d-flex justify-content-center align-items-center"
                        >
                          {activeFAQ === 8 ? (
                            <span>&#45;</span>
                          ) : (
                            <span>&#43;</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="8">
                    <div className="col-12 mb-3 home-faq-details home-faq-show  padding-for-home-faq">
                      There are several ways to earn Reward points through our
                      app. Please visit “Rewards Program” located on the app.
                    </div>
                  </Accordion.Collapse>
                </Card>
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      <div className="faq-btn-top-space">
        <div className="content">
          <div className="row w-100 d-flex justify-content-center justify-content-lg-end element-center-under-767px">
            <div className=" justify-content-right justify-content-lg-end text-right ">
              <button
                type="button"
                className="btn px-4 home-more-faq home-join-btns home-header-btn px-4 d-flex justify-content-center align-items-center text-white"
                onClick={() => history.push("/faq")}
              >
                VIEW MORE FAQ <BsArrowRightShort size="20px" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFAQ;
