import React from "react";

const Choose = () => {
  return (
    <>
      <div className="row choose-bg choose-main-container-top-space">
        <div className="position-absolute home-dot-people-img">
          <img className="w-75" src="assets/homepage/home_img_1.png" alt="" />
        </div>
        <div className="container-fluid  px-lg-5 position-relative d-flex flex-column mb-10">
          <div className="row mx-w px-4 px-lg-5">
            <div className="container-fluid px-lg-4 d-flex flex-column justify-content-center align-items-center">
              <div className=" mb-4 px-3 why-choose-text-top-space">
                <span className="home-work-heading text-000">WHY CHOOSE US</span>
              </div>
              <div className="mt-md-4  text-center  overflow-y-hidden setisfaction-text-bottom-space">
                <span className="text-center home-work-title2">
                Stress-free Cuts at Home
                </span>
              </div>
            </div>
            <div className="row my-lg-5  py-lg-0  justify-content-center">
              <div className="col-xl-5 col-md-6 py-lg-4  d-lg-flex  px-md-0 justify-content-md-start justify-content-center comfot-and-easetop-space">
                <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center justify-content-center mb-4 home_work_icons">
                    <svg width="30" height="34" viewBox="0 0 30 34" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5465 33.5517C14.7311 33.6482 14.9374 33.6972 15.1438 33.6956C15.3502 33.6939 15.5549 33.6433 15.7412 33.5452L21.6876 30.3664C23.3739 29.4675 24.6944 28.4624 25.7246 27.2922C27.9646 24.7426 29.1878 21.4887 29.166 18.1334L29.0955 7.06561C29.0888 5.79082 28.2516 4.65332 27.0133 4.23983L15.9509 0.528245C15.2848 0.302706 14.5549 0.307609 13.9005 0.539685L2.88008 4.38366C1.64851 4.81349 0.826337 5.95916 0.833049 7.23558L0.90352 18.2952C0.925333 21.6554 2.19047 24.8946 4.46737 27.4181C5.50767 28.5719 6.83992 29.5623 8.54466 30.4465L14.5465 33.5517ZM12.9723 20.5439C13.2206 20.7825 13.5428 20.9001 13.865 20.8969C14.1871 20.8952 14.5076 20.7743 14.7526 20.5324L21.2511 14.1258C21.7393 13.6437 21.7343 12.869 21.241 12.3934C20.746 11.9178 19.949 11.9211 19.4607 12.4032L13.8465 17.9371L11.5478 15.7275C11.0528 15.2519 10.2575 15.2568 9.76753 15.7389C9.27926 16.221 9.2843 16.9957 9.77928 17.4713L12.9723 20.5439Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="text-lg-left text-center qulaity-text-left-sppace">
                  <span className="home-choose-headings">Quality Services</span>
                  <p className="home-choose-desc my-lg-1 my-3 homeChoose-width width-100-on-768">
                    Our stylists are highly experienced <br />and qualified
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-md-6 py-lg-4  d-lg-flex  px-md-0 justify-content-md-start justify-content-center comfot-and-easetop-space">
                <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center justify-content-center mb-4 home_work_icons">
                    <svg width="30" height="34" viewBox="0 0 30 34" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5465 33.5517C14.7311 33.6482 14.9374 33.6972 15.1438 33.6956C15.3502 33.6939 15.5549 33.6433 15.7412 33.5452L21.6876 30.3664C23.3739 29.4675 24.6944 28.4624 25.7246 27.2922C27.9646 24.7426 29.1878 21.4887 29.166 18.1334L29.0955 7.06561C29.0888 5.79082 28.2516 4.65332 27.0133 4.23983L15.9509 0.528245C15.2848 0.302706 14.5549 0.307609 13.9005 0.539685L2.88008 4.38366C1.64851 4.81349 0.826337 5.95916 0.833049 7.23558L0.90352 18.2952C0.925333 21.6554 2.19047 24.8946 4.46737 27.4181C5.50767 28.5719 6.83992 29.5623 8.54466 30.4465L14.5465 33.5517ZM12.9723 20.5439C13.2206 20.7825 13.5428 20.9001 13.865 20.8969C14.1871 20.8952 14.5076 20.7743 14.7526 20.5324L21.2511 14.1258C21.7393 13.6437 21.7343 12.869 21.241 12.3934C20.746 11.9178 19.949 11.9211 19.4607 12.4032L13.8465 17.9371L11.5478 15.7275C11.0528 15.2519 10.2575 15.2568 9.76753 15.7389C9.27926 16.221 9.2843 16.9957 9.77928 17.4713L12.9723 20.5439Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="qulaity-text-left-sppace text-lg-left text-center">
                  <span className="home-choose-headings">
                    Professional Service
                  </span>
                  <p className="home-choose-desc my-lg-1 my-3 homeChoose-width width-100-on-768">
                  Our hand-picked stylists are professional  <br />  and prompt.  Enjoy being pampered <br /> at home.                    
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-md-6 py-lg-4 d-lg-flex  px-md-0 justify-content-md-start justify-content-center comfot-and-easetop-space">
                <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center justify-content-center mb-4 home_work_icons">
                    <svg width="30" height="34" viewBox="0 0 30 34" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5465 33.5517C14.7311 33.6482 14.9374 33.6972 15.1438 33.6956C15.3502 33.6939 15.5549 33.6433 15.7412 33.5452L21.6876 30.3664C23.3739 29.4675 24.6944 28.4624 25.7246 27.2922C27.9646 24.7426 29.1878 21.4887 29.166 18.1334L29.0955 7.06561C29.0888 5.79082 28.2516 4.65332 27.0133 4.23983L15.9509 0.528245C15.2848 0.302706 14.5549 0.307609 13.9005 0.539685L2.88008 4.38366C1.64851 4.81349 0.826337 5.95916 0.833049 7.23558L0.90352 18.2952C0.925333 21.6554 2.19047 24.8946 4.46737 27.4181C5.50767 28.5719 6.83992 29.5623 8.54466 30.4465L14.5465 33.5517ZM12.9723 20.5439C13.2206 20.7825 13.5428 20.9001 13.865 20.8969C14.1871 20.8952 14.5076 20.7743 14.7526 20.5324L21.2511 14.1258C21.7393 13.6437 21.7343 12.869 21.241 12.3934C20.746 11.9178 19.949 11.9211 19.4607 12.4032L13.8465 17.9371L11.5478 15.7275C11.0528 15.2519 10.2575 15.2568 9.76753 15.7389C9.27926 16.221 9.2843 16.9957 9.77928 17.4713L12.9723 20.5439Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="qulaity-text-left-sppace text-lg-left text-center">
                  <span className="home-choose-headings">Comfort and Ease</span>
                  <p className="home-choose-desc my-lg-1 my-3 homeChoose-width width-100-on-768">
                    Clients love how easy the app is to<br /> use and enjoy the 
                    services at-home.
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-md-6 py-lg-4  d-lg-flex  px-md-0 justify-content-md-start justify-content-center comfot-and-easetop-space">
                <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center justify-content-center mb-4 home_work_icons">
                    <svg width="30" height="34" viewBox="0 0 30 34" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5465 33.5517C14.7311 33.6482 14.9374 33.6972 15.1438 33.6956C15.3502 33.6939 15.5549 33.6433 15.7412 33.5452L21.6876 30.3664C23.3739 29.4675 24.6944 28.4624 25.7246 27.2922C27.9646 24.7426 29.1878 21.4887 29.166 18.1334L29.0955 7.06561C29.0888 5.79082 28.2516 4.65332 27.0133 4.23983L15.9509 0.528245C15.2848 0.302706 14.5549 0.307609 13.9005 0.539685L2.88008 4.38366C1.64851 4.81349 0.826337 5.95916 0.833049 7.23558L0.90352 18.2952C0.925333 21.6554 2.19047 24.8946 4.46737 27.4181C5.50767 28.5719 6.83992 29.5623 8.54466 30.4465L14.5465 33.5517ZM12.9723 20.5439C13.2206 20.7825 13.5428 20.9001 13.865 20.8969C14.1871 20.8952 14.5076 20.7743 14.7526 20.5324L21.2511 14.1258C21.7393 13.6437 21.7343 12.869 21.241 12.3934C20.746 11.9178 19.949 11.9211 19.4607 12.4032L13.8465 17.9371L11.5478 15.7275C11.0528 15.2519 10.2575 15.2568 9.76753 15.7389C9.27926 16.221 9.2843 16.9957 9.77928 17.4713L12.9723 20.5439Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="qulaity-text-left-sppace text-lg-left text-center">
                  <span className="home-choose-headings">
                    Safety first
                  </span>
                  <p className="home-choose-desc my-lg-1 my-3 homeChoose-width width-100-on-768">
                     Safety protocols are  always upheld to <br /> protect  our stylists and clients
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* temporarily comment */}

      {/* <div className="border-bottom pt-4 counting-border-bottom-padding">
        <div className="row ">
          <div className="position-absolute home-dot-people-img-2  d-lg-block ">
            <img className=" w-75 dotted-relative-image" src="assets/homepage/home_img_1.png" alt="" />
          </div>

          <div className="row w-100 mx-w count-top-space">
          
          
          <div className="col-md-6">
          <div className="row">
          <div className="col-6   align-items-center justify-content-xl-center">
              <div className="row w-100">
                <div className="col-md-5 col-xl-7 px-0">
                  <span className="text-left  text-lg-right overflow-y-hidden">
                    <h3 className="home-count overflow-y-hidden">
                      50K+
                    </h3>
                  </span>
                </div>
                <div className="col-md-7 col-lg-5 px-0">

                  <div className="d-block order-completed-top-space  justify-content-start text-lg-left  text-left text-md-left">
                    <p className="home-count-desc  mt-0 orders-left-space">Orders Completed</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-6   align-items-center justify-content-xl-center">
              <div className="row ">
                <div className="col-7 px-0">
                  <span className="text-left  text-lg-right overflow-y-hidden">
                    <h3 className="home-count overflow-y-hidden text-left">
                      100K+
                    </h3>
                  </span>
                </div>
                <div className="col-md-5 px-0">
                  <div className="d-block order-completed-top-space  text-lg-left  text-left text-md-left">
                    <p className="home-count-desc orders-left-space ">Registered Users</p>
                  </div>
                </div>
              </div>
            </div>


          </div>
          </div>
          
          <div className="col-md-6">
            <div className="row">
            <div className="col-6  align-items-center justify-content-xl-center">
              <div className="row w-100">
                <div className="col-6 col-lg-8 px-0">
                  <span className="text-left  text-lg-right overflow-y-hidden">
                    <h3 className="home-count overflow-y-hidden">
                      10K+
                    </h3>
                  </span>
                </div>
                <div className="col-md-6 col-lg-4 px-0">
                  <div className="d-block order-completed-top-space  justify-content-start text-lg-left  text-left text-md-left">
                    <p className="home-count-desc  mt-md-0 orders-left-space">Active Stylist</p>
                  </div>

                </div>
              </div>
            </div>


            <div className="col-6  align-items-center justify-content-xl-center">
              <div className="row width-100-under-768">
                <div className="col-5 px-0">
                  <span className="text-left  text-lg-right overflow-y-hidden">
                    <h3 className="home-count overflow-y-hidden">
                      4.8
                    </h3>
                  </span>
                </div>
                <div className="col-md-7 px-0">
                  <div className="d-block order-completed-top-space  justify-content-start text-lg-left  text-left text-md-left">
                    <p className="home-count-desc orders-left-space">Average Customer Ratings</p>
                  </div>

                </div>
              </div>
            </div>

            </div>
          </div>
          </div>

        </div>
      </div> */}
    </>
  );
};

export default Choose;
