import React, { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
// fesh desk
import Freshdesk from 'freshdesk-api';



const ContactForm = () => {
  
  const freshdesk = new Freshdesk('https://usinstacuts.freshdesk.com', '3cXXJ6HmsUnx1djGgJ8');

  let firstname, lastname, email, phone, message, agree;
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState('none');
  const [messagerror, setMessageError] = useState('')

  // Modal Operation
  const modalOperation = (display) => {
    setDisplay(display);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(agree);
    // console.log('resposne', firstname.value, lastname.value, email.value, phone.value, message.value, agree.checked)

    try {
      setLoading(true)
      freshdesk.createTicket({
        name: `${firstname.value} ${lastname.value}`,
        email: `${email.value}`,
        phone: `${phone.value}`,
        subject: 'Contact Form',
        description: `${message.value}`,
        status: 2,
        priority: 1
      }, function (error, data, extra) {
        setLoading(false)
        window.scrollTo({ top: 0, behavior: "smooth" })
        if (error) {
          setMessageError('Error: Try again later!');
        }
        if (data) {
          document.getElementById("contact-form").reset();
          modalOperation('block')
        }
      })
    } catch (error) {
      console.log(error)
      setMessageError('Error: Try again later!');
    }
  }
  return (
    <div className="over-x-unset">
      <div className="contact-us-container-new cntact-uform-position">
        <div className="contact-create-account overflow-y-hidden">Leave us a message</div>
        <div className="contact-desc">
          <div className="d-flex flex-column align-items-center">
            <div className="justify-content-center leave-us-lorem-text-form">
            For General or Business Inquiries. <br />
(Average Response Time 24 - 48 Hours)
            </div>
          </div>
        </div>
        <form onSubmit={(e) => onSubmit(e)} id='contact-form'>
          <div className="row justify-content-center input-fields-form-padding">
            <div className="col-md-6">
              <div className="contact-first-name mt-3 overflow-y-hidden">First Name</div>
              <input
                type="text"
                className="contact-first-input mt-3 pl-3"
                placeholder="First Name"
                ref={node => (firstname = node)}
                required
              />
            </div>
            <div className="col-md-6">
              <div className="contact-Last-name mt-3 overflow-y-hidden">Last Name</div>
              <input
                type="text"
                className="contact-Last-input mt-3  pl-3"
                placeholder="Last Name"
                ref={node => (lastname = node)}
                required
              />
            </div>
          </div>
          <div className="row justify-content-center input-fields-form-padding">
            <div className="col-md-6">
              <div className="contact-email-name mt-3 overflow-y-hidden">Email</div>
              <input
                type="email"
                className="contact-first-input mt-3  pl-3"
                placeholder="Email"
                ref={node => (email = node)}
                required
              />
            </div>
            <div className="col-md-6">
              <div className="contact-contact-name mt-3 overflow-y-hidden">Contact Number</div>
              <input
                type="number"
                className="contact-Last-input mt-3 pl-3"
                placeholder="Contact Number"
                ref={node => (phone = node)}
                required
              />
            </div>
          </div>
          <div className="row justify-content-center input-fields-form-padding">
            <div className="col-lg-12">
              <div className="contact-help-name mt-3  overflow-y-hidden">How we can help ?</div>
              <textarea
                type="text"
                className="contact-help-input mt-3 pl-3 pt-3"
                placeholder="Describe what we can help"
                ref={node => (message = node)}
                required
              />
            </div>
          </div>
          <div className="form-checkox-padding mt-3 ">
            <div className="row d-flex justify-content-lg-between justify-content-center">
              <div className="contact-agree-name mt-3">
                <input
                  className="stylist-checkbox pt-3"
                  id="stylist-checkbox-2"
                  type="checkbox"
                  ref={node => (agree = node)}
                  required
                />
                <label htmlFor="stylist-checkbox-2">I agree to the Terms &
                  Condition
                </label>
              </div>
              <div className="about-btn-contact d-none d-lg-block px-lg-2">
                <button type="submit" disabled={loading} className="contact-submit-btn" id="myBtn">
                  {loading ? 'Submitting...' :
                    <>SUBMIT <FiArrowRight width="20px" height='15px' /></>
                  }
                </button>

              </div>
              <div className="mx-3 row about-btn-contact d-block d-lg-none w-100">
                <button type="submit" disabled={loading} className="contact-submit-btn w-100" id="myBtn">
                  {loading ? 'Submitting...' :
                    <>SUBMIT <FiArrowRight width="20px" height='15px' /></>
                  }
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* modal */}
      <div id="myModal" class="modal" style={{ "display": display }}>
        <div className="modal-content overflow-y-hidden">
          <div className="text-right">
          <span style={{"overflow-y" : "hidden", float:"left", fontSize : "24px"}}>{messagerror !== '' ? 'Error' :'Thank You!'}</span>
          <p className="close" style={{"overflow-y" : "hidden"}} onClick={()=>modalOperation('none')}>&times;</p>
          </div>
          <hr /><br />
          <h4 className="overflow-y-hidden modal-thankyou-text">{messagerror !== '' ? messagerror  : 'We have recevied your message, We will get back to you shortly.'}</h4>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
