import React from "react";
import "../../styles/signup/signup.css";

const Signup = () => {
  return (
    <div className="mt-5 pt-5">
      <div className="login-container mt-5">
        <div className="signup-crreate-account mt-5 mb-4">Create Account</div>
        <div className="justify-content-center">
          <div className="signup-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            imperdiet etiam cras consequat.
          </div>
        </div>
        <div className="row justify-content-center mt-5 px-lg-0 px-2">
          <div className="col-lg-6">
            <div className="contact-first-name mt-4 mb-2 overflow-y-hidden">First Name</div>
            <input
              type="text"
              className="contact-first-input mt-2 pl-3 form-control"
              placeholder="First Name"
            />
          </div>
          <div className="col-lg-6">
            <div className="contact-Last-name mt-lg-4 mt-4 mb-2 overflow-y-hidden">Last Name</div>
            <input
              type="text"
              className="contact-Last-input mt-2 pl-3 form-control"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="row justify-content-center mt-lg-4 mt-3 px-lg-0 px-2">
          <div className="col-lg-12">
            <div className="contact-first-name mt-2 overflow-y-hidden">Email</div>
            <input
              type="text"
              className="contact-first-input mt-3 pl-3 form-control"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="row justify-content-center mt-lg-4 mt-3 px-lg-0 px-2">
          <div className="col-lg-12">
            <div className="contact-first-name mt-2 overflow-y-hidden">Phone Number</div>
            <input
              type="text"
              className="contact-first-input mt-3 pl-3 form-control"
              placeholder="Phone Number"
            />
          </div>
        </div>
        <div className="row justify-content-center mt-lg-4 mt-3 px-lg-0 px-2">
          <div className="col-lg-12 ">
            <div className="contact-first-name mt-2" style={{overflowY : "hidden"}}>Password</div>
            <div className="input-group form-pass-content py-3">
              <input
                type="password"
                className=" password-input px-3 py-4 border-right-0 form-control"
                id="inlineFormInputGroupUsername"
                placeholder="Password"
              />
              <div className="input-group-prepend stylist-password-border-left">
                <div className="input-group-text form-control form-icon border-left-0 rounded-right">
                  <svg width="20" height="16" viewBox="0 0 20 16" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.2882 0.00481888L10.002 0C5.86108 0 2.12926 2.92308 0.0609149 7.70583C-0.020305 7.89364 -0.020305 8.10636 0.0609149 8.29416L0.204239 8.61679C2.24638 13.0931 5.77544 15.8644 9.71179 15.9952L9.998 16C14.1389 16 17.8707 13.0769 19.9391 8.29416C20.0213 8.10399 20.0202 7.88839 19.9361 7.69904L19.7968 7.38563C17.7497 2.90091 14.2192 0.135466 10.2882 0.00481888ZM10.009 1.48942L10.2479 1.49456L10.5149 1.50845C13.7122 1.73484 16.6525 4.10553 18.429 7.99911L18.4197 8.02313C16.5987 12.0005 13.5569 14.3853 10.2589 14.505L10.004 14.5088L9.74693 14.5054L9.48061 14.4915C6.38271 14.2721 3.52637 12.0344 1.73914 8.3597L1.57 7.99911L1.72658 7.66619C3.61117 3.77315 6.69148 1.49027 10.009 1.48942ZM9.9995 4.11346C7.8391 4.11346 6.0885 5.85313 6.0885 8.0002C6.0885 10.1465 7.83929 11.8859 9.9995 11.8859C12.1598 11.8859 13.9115 10.1463 13.9115 8.0002C13.9115 5.85325 12.16 4.11346 9.9995 4.11346ZM9.9995 5.60378C11.3317 5.60378 12.4115 6.6764 12.4115 8.0002C12.4115 9.32312 11.3316 10.3956 9.9995 10.3956C8.66771 10.3956 7.5885 9.32338 7.5885 8.0002C7.5885 6.67614 8.6676 5.60378 9.9995 5.60378Z"
                      fill="#31312E"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-lg-4 mt-3  px-lg-0 px-2 mb-5">
          <div className="col-lg-12">
            <button type="btn" className="signup-btn mt-4 py-3 w-100">
              CRATE ACCOUNT
            </button>

            <div className="d-lg-flex stylist-form-signin mt-4 mb-5">
              <div className="d-flex align-items-center text-center flex-lg-row flex-column">
                Already have an account ?&nbsp;
                <a href="/signin">Sign in</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
