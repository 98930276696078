import React, { useState, useEffect } from "react";
import { BsArrowRightShort } from "react-icons/bs";

const StylistForm = ({ status, message, onValidated, scrolldown }) => {
    let firstname, lastname, email, phone, city, agree;
    const [display, setDisplay] = useState('none');


    const modalOperation = (display) => {
        setDisplay(display);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        try {
            onValidated({
                FNAME: firstname.value,
                LNAME: lastname.value,
                EMAIL: email.value,
                PHONE: phone.value,
                CITY: city.value,
                AGREE: agree.checked,
            });
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // console.log('resposne', status, message)
        if (status === 'error') {
            document.getElementById("stylist-form");
            modalOperation('block')
        }
        if (status === 'success') {

            document.getElementById("stylist-form").reset();
            modalOperation('block')
        }
    }, [status])

    return (
        <>
            <form onSubmit={(e) => onSubmit(e)} id='stylist-form'>
                <div className="stylist-form-top-space">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group stylist-form">
                                <label>First Name</label>
                                <input
                                    type="text"
                                    className="contact-first-input pl-3"
                                    placeholder="First Name"
                                    ref={node => (firstname = node)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group w-lg-50 w-100 stylist-form">
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    className="contact-first-input pl-3"
                                    placeholder="Last Name"
                                    ref={node => (lastname = node)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group stylist-form">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="contact-first-input px-3"
                                    placeholder="Email"
                                    ref={node => (email = node)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group stylist-form">
                                <label>Phone Number</label>
                                <input
                                    type="Number"
                                    className="contact-first-input px-3"
                                    placeholder="Phone Number"
                                    ref={node => (phone = node)}
                                    required
                                />
                            </div>
                        </div>
                        {/* <div className="col-12">
                <div className="form-group stylist-form">
                      <label>Password</label>
                      <div className="input-group form-pass-content ">
                        <input
                          type="text"
                          className="form-control password-input  border-right-0"
                          id="inlineFormInputGroupUsername"
                          placeholder="Password"
                        />
                        <div className="input-group-prepend stylist-password-border-left">
                          <div className="input-group-text bg-white form-icon border-left-0 rounded-right">
                            <svg
                              width="20"
                              height="16"
                              viewBox="0 0 20 16"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.2882 0.00481888L10.002 0C5.86108 0 2.12926 2.92308 0.0609149 7.70583C-0.020305 7.89364 -0.020305 8.10636 0.0609149 8.29416L0.204239 8.61679C2.24638 13.0931 5.77544 15.8644 9.71179 15.9952L9.998 16C14.1389 16 17.8707 13.0769 19.9391 8.29416C20.0213 8.10399 20.0202 7.88839 19.9361 7.69904L19.7968 7.38563C17.7497 2.90091 14.2192 0.135466 10.2882 0.00481888ZM10.009 1.48942L10.2479 1.49456L10.5149 1.50845C13.7122 1.73484 16.6525 4.10553 18.429 7.99911L18.4197 8.02313C16.5987 12.0005 13.5569 14.3853 10.2589 14.505L10.004 14.5088L9.74693 14.5054L9.48061 14.4915C6.38271 14.2721 3.52637 12.0344 1.73914 8.3597L1.57 7.99911L1.72658 7.66619C3.61117 3.77315 6.69148 1.49027 10.009 1.48942ZM9.9995 4.11346C7.8391 4.11346 6.0885 5.85313 6.0885 8.0002C6.0885 10.1465 7.83929 11.8859 9.9995 11.8859C12.1598 11.8859 13.9115 10.1463 13.9115 8.0002C13.9115 5.85325 12.16 4.11346 9.9995 4.11346ZM9.9995 5.60378C11.3317 5.60378 12.4115 6.6764 12.4115 8.0002C12.4115 9.32312 11.3316 10.3956 9.9995 10.3956C8.66771 10.3956 7.5885 9.32338 7.5885 8.0002C7.5885 6.67614 8.6676 5.60378 9.9995 5.60378Z"
                                fill="#31312E"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                </div> */}
                        <div className="col-12">
                            <div className="form-group stylist-form">
                                <label>City you'll serve in</label>
                                <input
                                    type="text"
                                    className="contact-first-input px-3"
                                    placeholder="City"
                                    ref={node => (city = node)}
                                    required={false}
                                />
                            </div>
                        </div>
                        <div className="c0l-12">
                            <div className="d-flex px-md-4 padding-left-for-stylist-checkbox">
                                <input
                                    className="stylist-checkbox"
                                    id="stylist-checkbox-2"
                                    type="checkbox"
                                    ref={node => (agree = node)}
                                    required
                                />
                                <label htmlFor="stylist-checkbox-2" />
                                <div className="stylist-form-chk-desc ml-3 checkbox-left-space-stylist">
                                    By proceeding, I agree to Instacuts <b> Terms of Use</b> and acknowledge that I have read the <b> Privacy Policy.</b>
                                </div>
                            </div>
                        </div>

                        <div className="row w-100 col-12 join-now-btn-top-space">
                            <div className="col-sm-4">
                                <div className="row d-flex justify-content-center">
                                    <button
                                        type="submit"
                                        className="btn  home-join-btns home-header-btn  d-flex text-center align-items-center justify-content-center btn-stylis-width text-white" id="myBtn"
                                        disabled={status === 'sending'}
                                    >
                                        {status === 'sending' ? 'Submitting...' :
                                            <>JOIN NOW <BsArrowRightShort size="20px" /></>
                                        }

                                    </button>
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <div className=" d-flex fz-12 fz-10-1200 fz-11-on-320 fz-9-1024 pt-3">
                                    Already have an account ?&nbsp;
                                    <a href="#1" className="text-bold-grey" onClick={()=>scrolldown()}><b>Sign in with app</b></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {/* modal */}
            <div id="myModal" class="modal" style={{ "display": display }}>
                <div className="modal-content overflow-y-hidden">
                    <div className="text-right">
                        <span style={{ "overflow-y": "hidden", float: "left", fontSize: "24px" }}> {(status === 'error') ? "Error" : "Success!"}</span>
                        <p className="close" style={{ "overflow-y": "hidden" }} onClick={() => modalOperation('none')}>&times;</p>
                    </div>
                    <hr /><br />
                    <h4 className="overflow-y-hidden modal-thankyou-text">{(status === 'error') ? message : "You are Successfully Register."}</h4>
                </div>
            </div>

        </>
    )
}

export default StylistForm
