import React from "react";
import { BsArrowRightShort } from "react-icons/bs";

const ImageCard = () => {
  return (
    <div className="container-for-image-card over-x-unset">
      <div className="content-imagecard   justify-content-center over-x-unset">
        <div className="row home-img-card  d-md-flex home-common align-items-center we-are-commited-top-over-div over-x-unset">
          <div className="col-md-6 d-flex flex-column justify-content-center we-are-commited-right-padding">
            <span className="">
              SAFETY
            </span>
            <span className="home-safety-card-title  overflow-y-hidden">
              We are committed to your safety
            </span>
            <p className="home-imgcard-contents ">
              We take the safety of our clients and hair stylists very
              seriously. In the same way that our hair stylists have become
              mobile, so have our safety protocols.
            </p>
            <div className="">
              <a href="/safety">
              <button
                type="button"
                className="btn home-btns home-header-btn px-4 d-flex align-items-center text-white home-join-btns "
                
              >
                LEARN MORE <BsArrowRightShort size="20px" />
              </button>
              </a>
            </div>
          </div>
          <div className="col-md-6 home-img-main-content">
            <img
              className="we-are-commited-image"
              src="assets/homepage/imagecard.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
