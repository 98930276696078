import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AOS from "aos";
import Home from "./components/home/home";
import { Nav, Navbar } from "react-bootstrap";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { SiInstagram } from "react-icons/si";
import { FaLinkedin } from "react-icons/fa";
import Stylist from "./components/stylist/stylist";
import FAQ from "./components/FAQ/FAQ";
import ContactUs from "./components/contactUs/contactUs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "aos/dist/aos.css";
import Safety from "./components/safety/safety";
import Signup from "./components/signup/signup";
import Signin from "./components/signin/signin";
import CustomerSafety from "./components/customerSafety/custSafety";
import StylistSafety from "./components/stylistSafety/stylistSafety";
import AboutUs from "./components/aboutus/aboutus";
import ScrollToTop from "./ScrollToTop";
import useWindowDimensions from './windowDimensions';



AOS.init({
  duration: 400,
});



const App = () => {

  // Variables
  const { width } = useWindowDimensions();
  const scrollHeight = 100;
  
  const scrolldown = x => {
    window.scrollTo({ behavior: 'smooth', top: x })
  }

  const SetAction = x => {
    sessionStorage.setItem(x, "true");
    window.location = `${window.location.origin}`
  }

  useEffect(()=>{
    const howitwork = sessionStorage.getItem("howitwork");
    const feature = sessionStorage.getItem("feature");
    const testimonial = sessionStorage.getItem("testimonial");
    if(howitwork === 'true'){
      scrolldown(width <= 425 ? 890 : width <= 768 ? 470 : 600);
      sessionStorage.removeItem("howitwork");
    }else if(feature === 'true'){
      scrolldown(width <= 425 ? 1800 : width <= 768 ? 1150 : 1350);
      sessionStorage.removeItem("feature");
    } else if(testimonial === 'true'){
      scrolldown(width <= 325 ? 7200 : width <= 425 ? 7000 : width <= 768 ? 5000 :5230);
      sessionStorage.removeItem("testimonial");
    }
  },[])
  

  useEffect(() => {
      window.addEventListener("scroll", headerChange);
    return function cleanup() {
        window.removeEventListener("scroll", headerChange);
    };
  });

  // set header Color on scrolling
  const headerChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > scrollHeight) {
      document.body
        .getElementsByTagName("nav")[0]
        .classList.add("header-scroll-takeover");
    } else {
      document.body
        .getElementsByTagName("nav")[0]
        .classList.remove("header-scroll-takeover");
    }
  };

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "100%";
  }

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  }


  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  return (
    <div>
      <div
        className={
          path === "/contact"
            ? "container-fluid  d-flex justify-content-center position-absolute dark-margin-auto"
            : " d-flex justify-content-center"
        }
      >
        
        <Navbar
          className={
            path === "/" ? 
            "col-xl-12  position-fixed col-md-12   nav-main d-block"
         : "col-xl-12  position-fixed col-md-12   nav-main d-block "
          }
          collapseOnSelect
          expand="lg"
          variant={path === "/contact" ? "dark" : "light"}
          style={{zIndex : 9999}}
        >
       <div className="navbar-container nav"
          >
          <Navbar.Brand href="/" className="nav-logo">
            {/* Instacuts */}
            <img
              src={
                path ===  "/contact"?
                "assets/homepage/Instacuts_Horizontal_white.svg":
                "assets/homepage/Instacuts_Horizontal_black.svg"
              }
              alt=""
              className="navbar-brand-height"
             />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"
          variant={path === "/contact" ? "" : "d-block"} />
          <Navbar.Collapse id="responsive-navbar-nav menu-hidden-on-smallcsreen">
            <Nav className="ml-auto ml-0 mt-1 nav-sub">
              <Nav.Link
                href="/"
                className={
                  path === "/"
                    ? "mx-0 mx-xl-2 mx-md-0 active"
                    : "mx-0 mx-xl-2 mx-md-0"
                }
              >
                HOME
              </Nav.Link>
              <Nav.Link
                href="/stylist"
                className={
                  path === "/stylist"
                    ? "mx-0 mx-xl-2 mx-md-0 active"
                    : "mx-0 mx-xl-2 mx-md-0"
                }
              >
                STYLIST
              </Nav.Link>
              <Nav.Link
                href="/safety"
                className={
                  path === "/safety"
                    ? "mx-0 mx-xl-2 mx-md-0 active"
                    : "mx-0 mx-xl-2 mx-md-0"
                }
              >
                SAFETY
              </Nav.Link>
              <Nav.Link
                href="/faq"
                className={
                  path === "/faq"
                    ? "mx-0 mx-xl-2 mx-md-0 active"
                    : "mx-0 mx-xl-2 mx-md-0"
                }
              >
                FAQ
              </Nav.Link>
              {/* <Nav.Link
                href="/aboutus"
                className={
                  path === "/aboutus" ? "mx-0 mx-md-2 active" : "mx-0 mx-md-2"
                }
              >
                ABOUT US
              </Nav.Link> */}
              
              <Nav.Link
                href="/contact"
                className={
                  path === "/contact"
                    ? "mx-0 mx-xl-2 mx-md-0 active"
                    : "mx-0 mx-xl-2 mx-md-0"
                }
              >
                CONTACT
              </Nav.Link>
              <Nav.Link
              onClick={()=>scrolldown(20000)}
                // href="/getapp"
                className={
                  path === "/getapp"
                    ? "mx-0 mx-xl-2 mx-md-0 active"
                    : "mx-0 mx-xl-2 mx-md-0"
                }
                
              >
                GET APP
              </Nav.Link>
              
            </Nav>
            {/* this will open when app is ready */}
            {/* <Nav className="d-flex home-nav-right align-items-md-center align-items-start">
              {path === "/signin" || path === "/signup" ? (
                ""
              ) : (
                <Nav.Link
                  className="home-nav-signin-btn mx-md-4 mx-0"
                  href="/signin"
                  style={{ color: path === "/contact" ? "#fff" : "#000" }}
                >
                  LOGIN
                </Nav.Link>
              )}
              <Nav.Link
                className="home-nav-signup-btn px-4 py-2 my-2"
                eventKey={2}
                href={
                  path === "/signin" || path === "/signup" ? "#" : "/signup"
                }
                style={{
                  color: path === "/contact" ? "#fff" : "#000",
                  border:
                    path === "/contact" ? "1px solid #fff" : "1px solid #000",
                }}
              >
                {path === "/signin" || path === "/signup"
                  ? "CONTINUE WITH MOBILE APP"
                  : "CREATE ACCOUNT"}
              </Nav.Link>
            </Nav> */}

          </Navbar.Collapse>

          <div id="mySidenav" className="sidenav" style={{zIndex : "9"}}>
            <a href="/" className="closebtn" onClick={closeNav}>&times;</a>
            <Nav.Link
              href="/"
              className={
                path === "/"
                  ? "mx-0 mx-xl-2 mx-md-0 active"
                  : "mx-0 mx-xl-2 mx-md-0"
              }
            >
              HOME
            </Nav.Link>
            <Nav.Link
              href="/stylist"
              className={
                path === "/stylist"
                  ? "mx-0 mx-xl-2 mx-md-0 active"
                  : "mx-0 mx-xl-2 mx-md-0"
              }
            >
              STYLIST
            </Nav.Link>
            <Nav.Link
              href="/safety"
              className={
                path === "/safety"
                  ? "mx-0 mx-xl-2 mx-md-0 active"
                  : "mx-0 mx-xl-2 mx-md-0"
              }
            >
              SAFETY
            </Nav.Link>
            <Nav.Link
              href="/faq"
              className={
                path === "/faq"
                  ? "mx-0 mx-xl-2 mx-md-0 active"
                  : "mx-0 mx-xl-2 mx-md-0"
              }
            >
              FAQ
            </Nav.Link>
            {/* <Nav.Link
              href="/aboutus"
              className={
                path === "/aboutus" ? "mx-0  m-0 active" : "mx-0 m-0 "
              }
            >
              ABOUT US
            </Nav.Link> */}
            <Nav.Link
              href="/contact"
              className={
                path === "/contact"
                  ? "mx-0 mx-xl-2 mx-md-0 active"
                  : "mx-0 mx-xl-2 mx-md-0"
              }
            >
              CONTACT
            </Nav.Link>
            
            {/* <Nav.Link
            onClick={()=>{ closeNav(); scrolldown();}}
              // onClick={()=>{scrolldown() closeNav}}
                // href="/getapp"
                className={
                  path === "/getapp"
                    ? "mx-0 mx-xl-2 mx-md-0 active"
                    : "mx-0 mx-xl-2 mx-md-0"
                }
                
              >
                GET APP
              </Nav.Link> */}
            {/* <Nav.Link
              className="home-nav-signin-btn  mx-0"
              href="/signin"
              style={{ color: path === "/contact" ? "#fff" : "#000" }}
            >
              LOGIN
            </Nav.Link> */}
            <div className="">
              <img
                  src={'assets/app-store.svg'}
                  alt="App Store"
                  className="play-store-on-mobile"
                />
                <br></br>
                <img
                  src={'assets/play-store.svg'}
                  alt="Play Store"
                  className="play-store-on-mobile"
                />
                </div>
          </div>
        
          <div className="ml-auto" 
                    variant={path === "/contact" ? "dark mx-w " : "light"}
                    >
            <div className=" home-nav-right align-items-md-center align-items-start ">
              {/* <Nav.Link
                className="home-nav-signup-btn  py-2 my-2 side-barhidden-on-largcsreen" 
                eventKey={2}
                href={
                  path === "/signin" || path === "/signup" ? "#" : "/signup"
                }
                style={{
                  color: path === "/contact" ? "#fff" : "#000",
                  border:
                    path === "/contact" ? "1px solid #fff" : "1px solid #000",
                }}
              >
                {path === "/signin" || path === "/signup"
                  ? "CONTINUE WITH MOBILE APP"
                  : "CREATE ACCOUNT"}
              </Nav.Link> */}
            </div>
            <img
                  src={path === '/contact'? 'assets/homepage/burger23.png' :'assets/homepage/Whats-a-Hamburger-Menu22.png'}
                  onClick={openNav}
                  alt="Menue"
                  className="burger-menue side-barhidden-on-largcsreen"
             />
             {/* <span className="burger-menue side-barhidden-on-largcsreen" onClick={openNav} >&#9776;</span>  */}
          </div>
        
       </div>
        </Navbar>
     
        
     </div>
      <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/stylist" component={Stylist} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/safety" component={Safety} />
          <Route exact path="/customerSafety" component={CustomerSafety} />
          <Route exact path="/stylistSafety" component={StylistSafety} />
          {/* <Route exact path="/aboutus" component={AboutUs} /> */}
        </Switch>
        </ScrollToTop>
      </BrowserRouter>
      <div className="footer-container py-lg-5 py-2">
        <div className=" mx-w footer-padding navbar-container">
          <div className="row d-flex justify-content-center px-lg-0 px-3 mt-5">
            <div className="col-lg-6 col-md-6 padding-for-footer-on-mobile">
              <div className="footer-making-text">
              Groom effortlessly  </div>
              <div className="footer-unique-text mt-20">
              For a limited time only, you’ll get 20% OFF your first service, so DOWNLOAD the app today!
              </div>
            </div>

            <div className="col-lg-5 col-md-5 my-xl-5 my-md-1 my-3 d-xl-flex d-flex flex-row justify-content-xl-end justify-content-center">
              <div className="d-flex footer-playstore-button-width foooter-playstore-align-center">
                <img
                  src={'assets/app-store.svg'}
                  alt="App Store"
                  className="home-apps-imgs mx-md-1 mx-2 d-xl-none d-xl-block d-block"
                />
                <img
                  src={'assets/play-store.svg'}
                  alt="Play Store"
                  className="home-apps-imgs mx-md-1 mx-2 d-xl-none d-xl-block d-blocke"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 my-xl-5 my-md-1 my-3 d-md-flex d-none d-xl-none flex-column align-items-end">
              <div className="row w-100">
                <img
                  src="assets/homepage/home_app_ios.png"
                  alt=""
                  className="home-apps-imgs  d-md-none mx-md-1 mx-2 col-md-12"
                />
              </div>
              <div className="row w-100">
                <img
                  src="assets/homepage/home_app_android.png"
                  alt=""
                  className="home-apps-imgs d-md-none  mx-md-1 mx-2 col-md-12"
                />
              </div>
            </div>
          </div>
          <hr className="footer-underline my-md-5 mb-5 justify-content-lg-center mx-w"></hr>

          <div className="row mx-w mt-5 justify-content-center px-lg-2 px-3 pt-md-5 padding-zer-for-footer-on-mobile">
            <div className="col-md-4 px-lg-5 padding-for-footer-on-mobile">
            <img
              src="assets/homepage/Instacuts_Horizontal_white.svg"
              alt=""
              className="navbar-brand-height-for-footer"
             />
              {/* <div className="footer-instacut-text mt-2">Instacuts</div> */}
              <div className="footer-instacut-desc mt-4 pr-lg-3">
              On-Demand Salon Services at Home!
              </div>
              <div className="footer-social-icons mb-3 mt-4">
              <div className="footer-instacut-desc mb-3">
              By Home Cuts, LLC              
              </div>
                {/* <a href="#/">
                  <span className="footer-twitter">
                    <AiOutlineTwitter size="25px" />
                  </span>
                </a>
                <a href="#/">
                  <span className="footer-twitter pl-3">
                    <AiFillFacebook size="25px" />
                  </span>
                </a>
                <a href="#/">
                  <span className="footer-twitter pl-3">
                    <SiInstagram size="20px" />
                  </span>
                </a>
                <a href="#/">
                  <span className="footer-twitter pl-3">
                    <FaLinkedin size="25px" />
                  </span>
                </a> */}
              </div>

            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div className="row">
                    <div className="col-6 overflow-y-hidden padding-for-footer-on-mobile">
                      <div className="footer-home-text mt-2">Home</div>
                      <a href="javascript:void(0)" onClick={()=>SetAction("howitwork")}>
                        <div className="footer-text mt-20">How It Work </div>
                      </a>
                      <a href="javascript:void(0)" onClick={()=>SetAction("feature")}>
                        <div className="footer-text mt-1">Features</div>
                      </a>
                      <a href="javascript:void(0)" onClick={()=>SetAction("testimonial")}>
                        <div className="footer-text mt-1 mb-2">Testimonial </div>
                      </a>
                      <span className="footer-box-underline"></span>
                    </div>
                    <div className="col-6 overflow-y-hidden padding-for-footer-on-mobile">
                      <div className="footer-Stylish-text mt-2">Stylist</div>
                      <a href="/stylist">
                        <div className="footer-text mt-20">Become a Stylist </div>
                      </a>
                      <a href="/stylist">
                        <div className="footer-text mt-1">Benefit </div>
                      </a>
                      <a href="/stylist">
                        <div className="footer-text mt-1 mb-2">Safety </div>
                      </a>
                      <span className="footer-box-underline"></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div className="row">
                    <div className="col-6 overflow-y-hidden padding-for-footer-on-mobile">
                      <div className="footer-Safety-text mt-2">Safety</div>
                      <a href="/safety">
                        <div className="footer-text mt-20">Safety </div>
                      </a>
                      <a href="/customerSafety">
                        <div className="footer-text mt-1">Customer Safety </div>
                      </a>
                      <a href="/stylistSafety">
                        <div className="footer-text mt-1 mb-2">Stylish Safety </div>
                      </a>
                      <span className="footer-box-underline"></span>
                    </div>
                    <div className="col-6 padding-for-footer-on-mobile">
                      <div className="footer-Company-text mt-2">Company</div>
                      <a href="/faq">
                        <div className="footer-text mt-20">FAQs </div>
                      </a>
                      <a href="/contact">
                        <div className="footer-text mt-1">Contact Us </div>
                      </a>
                      {/* <a href="/aboutus">
                        <div className="footer-text mt-1">About Us </div>
                      </a> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>





        {/* // imternal */}
        {/* <div className="row mx-w d-flex justify-content-center px-lg-0 px-3 mt-5">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-make">
            <div className="footer-making-text">
              Get Groomed Effortlessly
            </div>
            <div className="footer-unique-text mt-3">
              For a limited time only, you’ll get 20% OFF your first service, so DOWNLOAD the app today!
            </div>
          </div>

          <div className="col-lg-4 my-xl-5 my-md-1 my-3 d-md-none d-xl-flex d-flex flex-row justify-content-xl-end justify-content-center">
            <img
              src="assets/homepage/home_app_ios.png"
              alt=""
              className="home-apps-imgs mx-md-1 mx-2 d-md-none d-xl-block d-block"
            />
            <img
              src="assets/homepage/home_app_android.png"
              alt=""
              className="home-apps-imgs mx-md-1 mx-2 d-md-none d-xl-block d-block"
            />
          </div>
          <div className="col-lg-4 col-md-4 my-xl-5 my-md-1 my-3 d-md-flex d-none d-xl-none flex-column align-items-end">
            <div className="row w-100">
              <img
                src="assets/homepage/home_app_ios.png"
                alt=""
                className="home-apps-imgs mx-md-1 mx-2 col-md-12"
              />
            </div>
            <div className="row w-100">
              <img
                src="assets/homepage/home_app_android.png"
                alt=""
                className="home-apps-imgs mx-md-1 mx-2 col-md-12"
              />
            </div>
          </div>
        </div>
        <hr className="footer-underline my-md-5 mb-5 justify-content-lg-center mx-w"></hr>

        <div className="row mx-w mt-5 justify-content-center px-lg-0 px-3 pt-md-5">
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <div className="footer-instacut-text mt-2">INSTACUTS</div>
            <div className="footer-instacut-desc mt-4 w-75">
              On-Demand Salon Services at
              Home!
              <br />
              By Home Cuts, LLC
            </div>
            <div className="footer-social-icons mb-3 mt-5 ">
              <a href="#/">
                <span className="footer-twitter">
                  <AiOutlineTwitter size="25px" />
                </span>
              </a>
              <a href="#/">
                <span className="footer-twitter pl-3">
                  <AiFillFacebook size="25px" />
                </span>
              </a>
              <a href="#/">
                <span className="footer-twitter pl-3">
                  <SiInstagram size="20px" />
                </span>
              </a>
              <a href="#/">
                <span className="footer-twitter pl-3">
                  <FaLinkedin size="25px" />
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 footer-links">
            <div className="footer-home-text mt-2">Home</div>
            <a href="/">
              <div className="footer-text mt-3">How It Work </div>
            </a>
            <a href="/">
              <div className="footer-text mt-1">Features</div>
            </a>
            <a href="/">
              <div className="footer-text mt-1 mb-2">Testimonial </div>
            </a>
            <span className="footer-box-underline"></span>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 footer-links">
            <div className="footer-Stylish-text mt-2">Stylish</div>
            <a href="/stylist">
              <div className="footer-text mt-3">Become a Stylist </div>
            </a>npm
            <a href="/stylist">
              <div className="footer-text mt-1">Benefit </div>
            </a>
            <a href="/stylist">
              <div className="footer-text mt-1 mb-2">Safety </div>
            </a>
            <span className="footer-box-underline"></span>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 footer-links">
            <div className="footer-Safety-text mt-2">Safety</div>
            <a href="/safety">
              <div className="footer-text mt-3">Safety </div>
            </a>
            <a href="/customerSafety">
              <div className="footer-text mt-1">Customer Safety </div>
            </a>
            <a href="/stylistSafety">
              <div className="footer-text mt-1 mb-2">Stylish Safety </div>
            </a>
            <span className="footer-box-underline"></span>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 footer-links">
            <div className="footer-Company-text mt-2">Company</div>
            <a href="/faq">
              <div className="footer-text mt-3">FAQs </div>
            </a>
            <a href="/contact">
              <div className="footer-text mt-1">Contact Us </div>
            </a>
            <a href="/aboutus">
              <div className="footer-text mt-1">About Us </div>
            </a>
          </div>
        </div>*/}
      </div>
    </div>
  );
};
export default App;
