import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import CustomerProtocol from "./custSafety_component/custProtocol";
import Drives from "./custSafety_component/drives";
import Standard from "./custSafety_component/standard";

const CustomerSafety = () => {
  const scrolldown = () => {
    window.scrollTo({ behavior: 'smooth', top: 900 })
  }
  return (
    <div className="mt-5 pt-5">
    <div className="chhose-book-and-get-container-safety safety-top-sapce">
      <div className="content-imagecard justify-content-center">
        <div className="Row home-img-card  d-md-flex home-common align-items-center safety-containerr-inner-padding">
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <span className="safety-heding-bottom-space safety-card-top-space-45px">
            CUSTOMER SAFETY
            </span>
            <span className="home-safety-card-title">
            Safe, Comfortable <br /> & Reliable services
            </span>
            <p className="home-imgcard-contents">
            Feel confident and relaxed throughout your appointment by applying the following safety tips.</p>
            <div className="">
            <button
                type="button"
                onClick={()=>scrolldown()}
                className="btn home-btns home-header-btn px-4 d-flex align-items-center home-join-btns text-white"
              >
                Explore <BsArrowRightShort size="20px" />
              </button>
            </div>
          </div>
          <div className="col-md-6 home-img-main-content safety-card-bottom-space-45px px-0">
          <img
                className="home-img padding-top-for-20px-safety-image"
                src="assets/safetypage/customer-safty.png"
                alt=""
              />
          </div>
        </div>
      </div>
    </div>

    <Standard />
      <CustomerProtocol />
      <Drives />
  </div>
);
};

export default CustomerSafety;
