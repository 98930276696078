import React from "react";

const StylistJoin = () => {
  return (
    <div className="align-items-center text-center chhose-book-and-get-container">
      <div className="why-you-should-join-us-top-space overflow-y-hidden">
      <span className="home-work-title2 display-none-under-991px">Why You Should Join Us?</span>
        <span className="home-work-title2 display-none-after-992px">Why You Should <br></br> Join Us?</span>
      </div>
      <div className="container home-work-desc stylist-join-desc we-are-incredible-padding">
      We are incredibly proud of the platform we built, and we make it a priority to showcase your work for other clients  to find and book services with you.
      </div>
      {/* <div className="container home-work-desc stylist-join-desc we-are-incredible-padding">
      We are incredibly proud of the platform we built, and we make it a priority to showcase your work for other clients  to find and book services with you.<br></br> You never need to worry about finding clients once you join Instacuts.
      </div> */}
      <div className="">
        <div className="row">
          <div className="col-md-4">
            <div className="col-md-12 py-2 d-flex justify-content-center over-x-unset">
              <div className="d-flex align-items-center justify-content-center home_work_icons flexibility-box-shadow">
                <div className="d-flex align-items-center text-white justify-content-center home-join-icons ">
                  1
                </div>
              </div>
            </div>
            <div className="col-md-12 stylist-join-content-title flexibility-heading-top-space fz-26px-flexibility">
              Flexibility
            </div>
            <div className="col-md-12 stylist-join-content-desc take-on-new-top-space">
              Take on new clients as you see fit and stay in control of your own
              schedule.
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 py-2 d-flex justify-content-center over-x-unset">
              <div className="d-flex align-items-center justify-content-center home_work_icons flexibility-box-shadow stylist-page-number-top-space">
                <div className="d-flex align-items-center text-white justify-content-center home-join-icons">
                  2
                </div>
              </div>
            </div>
            <div className="col-md-12 stylist-join-content-title flexibility-heading-top-space fz-26px-flexibility">Safety</div>
            <div className="col-md-12 stylist-join-content-desc take-on-new-top-space">
              COVID-19 safety protocols are strictly implemented to ensure the health and well being of customers and stylists alike
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 py-2 d-flex justify-content-center over-x-unset">
              <div className="d-flex align-items-center justify-content-center home_work_icons flexibility-box-shadow stylist-page-number-top-space">
                <div className="d-flex align-items-center text-white justify-content-center home-join-icons">
                  3
                </div>
              </div>
            </div>
            <div className="col-md-12 stylist-join-content-title flexibility-heading-top-space fz-26px-flexibility">Income</div>
            <div className="col-md-12 stylist-join-content-desc take-on-new-top-space">
            Make more than working from a salon - our stylists earn 150% more than an average salon stylist.
            </div>
          </div>
          <div className="row margin-top-60px-for-row-stylist">
            
          <div className="col-md-4">
            <div className="col-md-12 py-2 d-flex justify-content-center  over-x-unset">
              <div className="d-flex align-items-center justify-content-center home_work_icons flexibility-box-shadow stylist-page-number-top-space">
                <div className="d-flex align-items-center text-white justify-content-center home-join-icons">
                  4
                </div>
              </div>
            </div>
            {/* =======media css not working======= */}
            <div className="col-md-12 stylist-join-content-title flexibility-heading-top-space fz-26px-flexibility">
              Custom Services
            </div>
            <div className="col-md-12 stylist-join-content-desc take-on-new-top-space">
            As you grow, you’ll have the option to 
create custom services & set own prices.
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 py-2 d-flex justify-content-center over-x-unset">
              <div className="d-flex align-items-center justify-content-center home_work_icons flexibility-box-shadow stylist-page-number-top-space">
                <div className="d-flex align-items-center text-white justify-content-center home-join-icons">
                  5
                </div>
              </div>
            </div>
            <div className="col-md-12 stylist-join-content-title flexibility-heading-top-space fz-26px-flexibility">
              Peace of Mind
            </div>
            <div className="col-md-12 stylist-join-content-desc take-on-new-top-space">
            You won’t have to worry about job security 
if salons are forced to close again.
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 py-2 d-flex justify-content-center over-x-unset">
              <div className="d-flex align-items-center justify-content-center home_work_icons flexibility-box-shadow stylist-page-number-top-space">
                <div className="d-flex align-items-center text-white justify-content-center home-join-icons">
                  6
                </div>
              </div>
            </div>
            <div className="col-md-12 stylist-join-content-title flexibility-heading-top-space fz-26px-flexibility">On time</div>
            <div className="col-md-12 stylist-join-content-desc take-on-new-top-space">
            Payments will be processed ontime, 
everytime, straight to your bank.
            </div>
          </div>
        
          </div>
          </div>
      </div>
    </div>
  );
};

export default StylistJoin;
