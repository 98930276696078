import React, { useState } from "react";
import "../../styles/FAQ/faq.css";
import FAQapp from "./FAQ_components/faqapp";
import FAQcustomer from "./FAQ_components/faqcustomer";
import FAQstylist from "./FAQ_components/faqstylist";

const FAQ = () => {
  const [faq, setFaq] = useState("app");

  return (
    <div className="mt-md-5 pt-5">
      <div className="align-items-center text-center chhose-book-and-get-container-faq  padding-horizantly-10px-767px">
        <div className="faq-page-top-margin overflow-y-hidden">
          <span className="home-work-title">Frequently Asked Questions</span>
        </div>

        {/* <div className="">
          <div className="">
            <div className="input-group border rounded-pill faq-search-bar-height overflow-y-hidden">
              <input
                type="text"
                className="form-control faq-serach-input border-0 "
                placeholder="Ask a question"
              />
              <div className="input-group-btn faq-search-bar-button-padding">
                <button className="btn btn-default " type="submit">
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <circle
                      cx="9.7659"
                      cy="9.76639"
                      r="8.98856"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.0176 16.4849L19.5416 19.9997"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className=" d-flex align-items-center justify-content-center text-center faq-card-row-top-space chhose-book-and-get-container over-x-unset padding-horizantly-10px-767px">
        <div className="row d-lg-flex  over-x-unset">
          <div className="col-lg-4 p-lg-4 my-lg-0 mb-5 over-x-unset">
            <div
              className={
                faq === "app"
                  ? "faq-active py-4 faq-card over-x-unset"
                  : "py-4 faq-card over-x-unset"
              }
              style={{
                borderRadius: "15px",
                border: faq === "app" ? "1px solid #000" : "none",
              }}
              onClick={() => setFaq("app")}
            >
              <div className="my-5">
                <img src="assets/faqpage/faq_img_1.svg" alt="" />
              </div>
              <div className="px-lg-4 px-5 pb-4">
                <h3 className="faq-card-heading">App</h3>
                <h6 className="faq-card-desc px-md-2">
                  Functionality <br />
                  Features
                  <br />
                  Troubleshooting
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-lg-4 my-lg-0 mb-5 over-x-unset">
            <div
              className={
                faq === "customer"
                  ? "faq-active py-4 faq-card over-x-unset"
                  : "py-4 faq-card over-x-unset"
              }
              style={{
                borderRadius: "15px",
                border: faq === "customer" ? "1px solid #000" : "none",
              }}
              onClick={() => setFaq("customer")}
            >
              <div className="my-5">
                <img src="assets/faqpage/faq_img_2.svg" alt="" />
              </div>
              <div className="px-lg-4 px-5 pb-4">
                <h3 className="faq-card-heading">Customer</h3>
                <h6 className="faq-card-desc px-md-2">
                  Bookings <br />
                  Payments <br />
                  Troubleshooting
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-lg-4 my-lg-0 mb-5 over-x-unset">
            <div
              className={
                faq === "stylist"
                  ? "faq-active py-4 faq-card over-x-unset"
                  : "py-4 faq-card over-x-unset"
              }
              style={{
                borderRadius: "15px",
                border: faq === "stylist" ? "1px solid #000" : "none",
              }}
              onClick={() => setFaq("stylist")}
            >
              <div className="my-5">
                <img src="assets/faqpage/faq_img_3.svg" alt="" />
              </div>
              <div className="px-lg-4 pb-4 px-5 pb-4">
                <h3 className="faq-card-heading">Stylist</h3>
                <h6 className="faq-card-desc px-md-2">
                  Bookings <br />
                  Schedule <br />
                  Troubleshooting
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-container">
      {faq === "app" ? (
        <FAQapp />
      ) : faq === "customer" ? (
        <FAQcustomer />
      ) : (
        <FAQstylist />
      )}
     </div>
    </div>
  );
};

export default FAQ;
