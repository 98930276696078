import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const FAQapp = () => {
  const [activeFAQ, setActiveFAQ] = useState(null);

  const handleToggle = (i) => {
    activeFAQ === i ? setActiveFAQ(null) : setActiveFAQ(i);
  };

  return (
    <div className="align-items-center text-center customer-testimonials-top-space-for-faq chhose-book-and-get-container p-3 p-md-4">
      <h1 className="faq-fadeIn overflow-y-hidden font-size-32px-under-767">
        <b>Apps FAQs</b>
      </h1>
      <div className="col-lg-12 text-center my-4 faq-fadeIn">
        <p className="home-work-desc px-lg-5 responsive-faq w-100-991-common">
          We’ve put together a list of answers for you, <br /> If you don’t find
          answers to your questions, feel free to contact us.
        </p>
      </div>
      <div className="">
        <Accordion>
          <div className="content-box">
            <div className="content-box">
              <div className="row py-lg-5 py-4 customize-bottom-cards-faq-768">
                <div className="col-lg-12 px-lg-4 px-md-2 p-0">
                  {/* <div className="row"> */}

                  <Card
                    className="text-left my-1 home-faq-card-details"
                    style={{
                      backgroundColor: activeFAQ !== 0 ? "#fff" : "#EDF1F5",
                    }}
                  >
                    <Accordion.Toggle
                      className="text-left"
                      as={Button}
                      variant=""
                      eventKey="0"
                      onClick={() => handleToggle(0)}
                    >
                      <div className="row d-flex py-2 px-1 justify-content-between align-items-center home-faq-extend-title faq-card-height faq-faq-button-padding">
                        <div className="col-9 faq-extand-title faq-extand-title">
                          How old do I have to be to register on the Instacuts
                          app?
                        </div>
                        <div className="col-3 col-lg-1 d-flex justify-content-end align-items-center collapse-button-faq over-x-unset">
                          <button
                            type="button"
                            className="border-0 home-faq-btn px-3 d-flex justify-content-center align-items-center"
                          >
                            {activeFAQ === 0 ? (
                              <span>&#45;</span>
                            ) : (
                              <span>&#43;</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <div className="col-lg-11 mb-3 home-faq-details home-faq-show stylist-faq-padding">
                        You must be 18 years or older to make an account on the
                        app. You can add as many minors to your account. Our
                        clients enjoy the stress-free delivery of a professional
                        hair cut at home for their kids
                      </div>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className="text-left my-1 mt-3 home-faq-card-details"
                    style={{
                      backgroundColor: activeFAQ !== 1 ? "#fff" : "#EDF1F5",
                    }}
                  >
                    <Accordion.Toggle
                      className="text-left"
                      as={Button}
                      variant=""
                      eventKey="1"
                      onClick={() => handleToggle(1)}
                    >
                      <div className="row d-flex py-2 px-1 justify-content-between align-items-center home-faq-extend-title faq-card-height faq-faq-button-padding">
                        <div className="col-9 faq-extand-title">
                          Can I book appointments for multiple people at the
                          same time?
                        </div>
                        <div className="col-3 col-lg-1 d-flex justify-content-end align-items-center collapse-button-faq over-x-unset">
                          <button
                            type="button"
                            className="border-0 home-faq-btn px-3 d-flex justify-content-center align-items-center"
                          >
                            {activeFAQ === 1 ? (
                              <span>&#45;</span>
                            ) : (
                              <span>&#43;</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <div className="col-lg-11 mb-3 home-faq-details home-faq-show stylist-faq-padding">
                        Yes. Our technology allows you to schedule multiple
                        appointments (based on the availability of
                        professionals) seamlessly through the app.
                      </div>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className="text-left my-1 mt-3 home-faq-card-details"
                    style={{
                      backgroundColor: activeFAQ !== 2 ? "#fff" : "#EDF1F5",
                    }}
                  >
                    <Accordion.Toggle
                      className="text-left"
                      as={Button}
                      variant=""
                      eventKey="2"
                      onClick={() => handleToggle(2)}
                    >
                      <div className="row d-flex py-2 px-1 justify-content-between align-items-center home-faq-extend-title faq-card-height faq-faq-button-padding">
                        <div className="col-9 faq-extand-title">
                          How do I earn Reward Points?
                        </div>
                        <div className="col-3 col-lg-1 d-flex justify-content-end align-items-center collapse-button-faq over-x-unset">
                          <button
                            type="button"
                            className="border-0 home-faq-btn px-3 d-flex justify-content-center align-items-center"
                          >
                            {activeFAQ === 2 ? (
                              <span>&#45;</span>
                            ) : (
                              <span>&#43;</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <div className="col-lg-11 mb-3 home-faq-details home-faq-show stylist-faq-padding">
                        There are several ways to earn Reward points through our
                        app. Please visit “Rewards Program” located on the app
                        and the website for details.
                      </div>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQapp;
