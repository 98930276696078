import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const Journy = () => {
  const history = useHistory();

  return (
    <div className="chhose-book-and-get-container building-a-safer-padding">
      <div className="row">
        <div className="col-md-6 building-a-safer-text-padding">
          <div className="row safety-footer-heading ">
            Building a safer & more comfortable salon experience
          </div>
          <div className="row safety-footer-text ">
            The safety protocols we have in place are unique to our platform, providing you with the highest safety standards in the salon industry.

          </div>

        </div>
        <div className="col-md-6">
          <div className="row">
          <div className="col-sm-6 px-0 px-md-3">
            <div className="d-flex justify-content-center justify-content-md-start">
              <img
                src="assets/safetypage/safety_cust_join.png"
                alt="Footer1"
                className="customer-safety-image"
              />
            </div>
            <div className="safety-image1-heading  justify-content-center justify-content-md-start customer-safety-below-text">
              Customer Safety
            </div>
            <div className="safety-image-text1">
              Find out indepth about the safety features for our Customers.
            </div>
            <div className="d-flex customer-safety-bottom-button-padding  justify-content-md-start">
              <button
                className="safety-footer-btn1 py-2 px-4"
                onClick={() => history.push("/customersafety")}
              >
                LEARN MORE <BsArrowRightShort size="2em" />
              </button>
            </div>
          </div>
          <div className="col-sm-6 safety-page-bottom-margin px-0 px-md-3">
            <div className="d-flex justify-content-center justify-content-md-start stylist-safety-image-top-space-safety">
              <img
                src="assets/safetypage/safety_stylist_join.png"
                alt="Footer1"
                className="customer-safety-image"
              />
            </div>
            <div className="safety-image1-heading justify-content-center justify-content-md-start customer-safety-below-text">
              Stylist Safety
            </div>
            <div className="safety-image-text1">
              Find out indepth about the safety features for our Stylists.
            </div>
            <div className="d-flex customer-safety-bottom-button-padding  justify-content-md-start">
              <button
                className="safety-footer-btn1 py-2 px-4"
                onClick={() => history.push("/stylistsafety")}
              >
                LEARN MORE <BsArrowRightShort size="2em" />
              </button>
            </div>
          </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Journy;
