import React from "react";

const Appcontent = () => {
  return (
    <div className="chhose-book-and-get-container get-groomed-top-space">
      <div className="content-imagecard  justify-content-center">
        <div className="col-md-12  home-img-card  d-md-flex home-common align-items-center">
          <div className="col-md-6 d-flex flex-column justify-content-center get-groomed-text-padding">
            <div className="position-absolute home-app-image2 home-app-content-img d-sm-block ">
              <img src="assets/homepage/home_img_1.png" alt="" />
            </div>
            <span className="home-safety-card-title ">
              Get Groomed Effortlessly
            </span>
            <p className="home-imgcard-contents For-a-limited-top-space">
              For a limited time only, you’ll get 20% OFF your first,
              third and fifth booking, so DOWNLOAD the app today!
            </p>
            <span className="home-app-download For-a-limited-top-space">DOWNLOAD HERE &gt;</span>
            <div className="home-apps-img d-flex justify-content-between justify-content-sm-start mt-4">
              <img
                src="assets/homepage/home_app_ios.png"
                className="home-app-content-appimg"
                alt=""
              />
              <img
                src="assets/homepage/home_app_android.png"
                className="home-app-content-appimg mx-sm-1"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 home-img-main-content over-x-unset">
            <img
              className="get-groomed-image"
              src="assets/homepage/screens.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appcontent;
