import React from "react";
// import { BsBellFill } from "react-icons/bs";
// import { BsArrowRightShort } from "react-icons/bs";

const Career = () => {
  return (
    <div className="align-items-center text-center chhose-book-and-get-container">
      <div className="we-also-have-a-greate-padding ">
        <span className="home-work-title2 d-md-block overflow-y-hidden ">
          We also have a great 
          Rewards Program!
        </span>
      </div>
      <div className="home-work-desc">
            We care about you and your growth, hence we’ve created <br></br>
            multiple streams of income through one platform.
      </div>

      <div className="miled-stone-top-space">
        <div className="row">
          <div className="col-lg-6 justify-content-center">
            <div className="d-lg-flex miled-stone-text-top-space">
              <div className="col-lg-3 px-md-4 d-flex justify-content-lg-end justify-content-center">
                <div className="d-flex align-items-center text-white justify-content-center mb-4 home-join-icons stylist">
                  1
                </div>
              </div>
              <div className="d-flex flex-column align-items-lg-start align-items-center px-lg-3 px-0 text-lg-left text-center">
                <span className="home-choose-headings">
                  Milestone Based Rewards
                </span>
                <p className="home-choose-desc py-3 py-lg-0 stylist-feature padding-for-text-below-milestone">
                As you service more and more clients, our system will grant you bonus points and give
you access to more new clients.
                </p>
              </div>
            </div>
            <div className="d-lg-flex refferal-reaward-top-space">
              <div className="col-lg-3 px-md-4 d-flex justify-content-lg-end justify-content-center stylist-page-number-top-space">
                <div className="d-flex align-items-center text-white justify-content-center mb-4 home-join-icons stylist">
                  2
                </div>
              </div>
              <div className="d-flex flex-column align-items-lg-start align-items-center px-lg-3 px-0 text-lg-left text-center">
                <span className="home-choose-headings">Referral Rewards</span>
                <p className="home-choose-desc py-3 py-lg-0 stylist-feature padding-for-text-below-milestone">
                As you refer other stylists and clients to use our platform, you will also earn
Reward credits to withdraw straight to your bank.
                </p>
              </div>
            </div>
            {/* <div className="d-lg-flex my-3 px-lg-4">
              <div className="col-lg-3 d-flex justify-content-center">
                <div className="d-flex align-items-center text-white justify-content-center mb-4 home-join-icons stylist">
                  3
                </div>
              </div>

              <div className="row d-flex flex-column align-items-lg-start align-items-center px-lg-3 px-0 text-lg-left text-center">
                <span className="home-choose-headings">Reason One</span>
                <p className="home-choose-desc py-3 py-lg-0">
                  With lots of unique blocks, you can easily build a page
                  without coding.
                </p>
              </div>
            </div>
           */}
          </div>
          <div className="col-lg-6 px-md-5 d-flex justify-content-start align-items-center overflow-xunset-under-1023 px-0">
            <div className="overflow-xunset-under-1023">
              <img
                src="assets/stylistpage/stylist_slider_img_2.png"
                alt=""
                className="we-also-have-image-size"
              />
            </div>
            <div
              className="position-absolute justify-content-center stylist-cng-subimg-1 stylist-cng-subimg-2 over-x-unset"
              style={{ left: 0 }}
            >
              <div className="d-flex shadow justify-content-center align-items-center p-md-2">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex justify-content-center">
                    <div className="col-lg-12 d-flex align-items-center">
                      <svg
                        width="37"
                        height="36"
                        viewBox="0 0 29 28"
                        fill="none"
                      >
                        <circle
                          cx="14.5932"
                          cy="13.9999"
                          r="13.7143"
                          fill="black"
                        />
                        <path
                          d="M19.8187 17.6618C19.8061 17.6445 18.5491 15.9051 18.5491 14.4624V11.8862C18.5491 9.75206 16.7779 8.02197 14.5931 8.02197C12.4082 8.02197 10.6371 9.75206 10.6371 11.8862V14.4624C10.6371 15.9051 9.3801 17.6445 9.36744 17.6618C9.31005 17.7403 9.3024 17.8436 9.34761 17.9294C9.39283 18.0152 9.48334 18.0691 9.58212 18.0691H19.604C19.7028 18.0691 19.7933 18.0152 19.8386 17.9294C19.8838 17.8436 19.8761 17.7403 19.8187 17.6618Z"
                          fill="white"
                        />
                        <path
                          d="M14.845 20.0786C15.5907 20.077 16.2271 19.4406 16.352 18.5715H13.3379C13.4628 19.4406 14.0992 20.077 14.845 20.0786Z"
                          fill="white"
                        />
                      </svg>
                      <div className="d-flex px-2 flex-column stylist-cng-subimg-details-1 text-left">
                        <span>New Order!</span>

                        <span>Order from Samantha William</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
