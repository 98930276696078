import React from "react";
import "../../../styles/safety/safety.css";

const Helping = () => {
  return (
    <>
     <div className="chhose-book-and-get-container building-a-safer-padding">
        <div className="row justify-content-center ">
          <div className="col-md-12">
            <div className="safety-text text-md-left text-center covid-safety-top-space">
              COVID safety
            </div>
            <div className="safety-desc-text text-md-left text-center">
            Stylists will carry digital thermometers to check readings before starting the service, to ensure the safety of 
              both stylists & customers.
            </div>
          </div>

          <div className="row d-flex safety-photos-top-space">
            <div className="col-md-4">
              <div className="safety-bg d-flex flex-md-column justify-content-center">
              
                <img
                  src="assets/safetypage/helping_img_1.png"
                  alt=""
                  className="safety-image"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-8 text-center overflow-y-hidden hhelping-image-div">
                <img
                  src="assets/safetypage/safety_helping_imgs.png"
                  alt=""
                  className="safety-helping-image"
                />
                <img
                        src="assets/safetypage/helping_img_2.png"
                        alt=""
                        className="stylist-with-carry-second-image"
                      />
                </div>
                <div className="col-4">
                <img
                  src="assets/safetypage/helping_img_3.png"
                  alt=""
                  className="stylist-with-carry-third-image"
                />
                </div>
                <div className="col-lg-9">
                <div className="safety-common-text mt-2 text-md-left text-center safety-and-constantly-paddinf">
                    We are constantly keeping our safety procedures up to date with the latest best practices as recommended by the CDC and the WHO.
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Helping;
