import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const FAQstylist = () => {
  const [activeFAQ, setActiveFAQ] = useState(null);

  const handleToggle = (i) => {
    activeFAQ === i ? setActiveFAQ(null) : setActiveFAQ(i);
  };

  return (
    <div className="align-items-center text-center customer-testimonials-top-space-for-faq chhose-book-and-get-container p-3 p-md-4">
      <h1 className="faq-fadeIn overflow-y-hidden font-size-32px-under-767">
        <b>Stylist FAQs</b>
      </h1>
      <div className="col-lg-12 text-center my-4 faq-fadeIn">
        <p className="faq-work-desc px-lg-5 responsive-faq w-100-991-common">
          We’ve put together a list of answers for you, If you don’t find
          answers to your questions, feel free to contact us.
        </p>
      </div>
      <div className="">
        <Accordion>
          <div className="content-box">
            <div className="content-box">
              <div className="row py-lg-5 py-4 customize-bottom-cards-faq-768">
                <div className="col-lg-12 px-lg-4 px-md-2 p-0">
                  {/* <div className="row"> */}

                  <Card
                    className="text-left my-1 home-faq-card-details"
                    style={{
                      backgroundColor: activeFAQ !== 0 ? "#fff" : "#EDF1F5",
                    }}
                  >
                    <Accordion.Toggle
                      className="text-left"
                      as={Button}
                      variant=""
                      eventKey="0"
                      onClick={() => handleToggle(0)}
                    >
                      <div className="row d-flex py-2 px-1 justify-content-between align-items-center home-faq-extend-title faq-card-height faq-faq-button-padding">
                        <div className="col-9 faq-extand-title faq-extand-title">
                          How do I receive notifications for an appointment?
                        </div>
                        <div className="col-3 col-lg-1 d-flex justify-content-end align-items-center collapse-button-faq over-x-unset">
                          <button
                            type="button"
                            className="border-0 home-faq-btn px-3 d-flex justify-content-center align-items-center"
                          >
                            {activeFAQ === 0 ? (
                              <span>&#45;</span>
                            ) : (
                              <span>&#43;</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <div className="col-lg-11 mb-3 home-faq-details home-faq-show stylist-faq-padding">
                        Once you are onboarded, the app explains the process of
                        receiving and booking appointments.
                      </div>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className="text-left my-1 mt-3 home-faq-card-details"
                    style={{
                      backgroundColor: activeFAQ !== 1 ? "#fff" : "#EDF1F5",
                    }}
                  >
                    <Accordion.Toggle
                      className="text-left"
                      as={Button}
                      variant=""
                      eventKey="1"
                      onClick={() => handleToggle(1)}
                    >
                      <div className="row d-flex py-2 px-1 justify-content-between align-items-center home-faq-extend-title faq-card-height faq-faq-button-padding">
                        <div className="col-9 faq-extand-title">
                          Do I need my own equipment?
                        </div>
                        <div className="col-3 col-lg-1 d-flex justify-content-end align-items-center collapse-button-faq over-x-unset">
                          <button
                            type="button"
                            className="border-0 home-faq-btn px-3 d-flex justify-content-center align-items-center"
                          >
                            {activeFAQ === 1 ? (
                              <span>&#45;</span>
                            ) : (
                              <span>&#43;</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <div className="col-lg-11 mb-3 home-faq-details home-faq-show stylist-faq-padding">
                        Yes, each stylist should have their own haircutting and
                        grooming supplies. However, Instacuts will provide
                        stylists with a customer cape, stylist smock, drop
                        cloth, and sterilization and clean-up supplies.
                      </div>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className="text-left my-1 mt-3 home-faq-card-details"
                    style={{
                      backgroundColor: activeFAQ !== 2 ? "#fff" : "#EDF1F5",
                    }}
                  >
                    <Accordion.Toggle
                      className="text-left"
                      as={Button}
                      variant=""
                      eventKey="2"
                      onClick={() => handleToggle(2)}
                    >
                      <div className="row d-flex py-2 px-1 justify-content-between align-items-center home-faq-extend-title faq-card-height faq-faq-button-padding">
                        <div className="col-9 faq-extand-title">
                          What if I have a concern or issue with an appointment?
                        </div>
                        <div className="col-3 col-lg-1 d-flex justify-content-end align-items-center collapse-button-faq over-x-unset">
                          <button
                            type="button"
                            className="border-0 home-faq-btn px-3 d-flex justify-content-center align-items-center"
                          >
                            {activeFAQ === 2 ? (
                              <span>&#45;</span>
                            ) : (
                              <span>&#43;</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <div className="col-lg-11 mb-3 home-faq-details home-faq-show stylist-faq-padding">
                        Our customer representatives work around the clock to
                        answer your questions. Feel free to give us a call if
                        any questions arise.
                      </div>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQstylist;
